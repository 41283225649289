import { Typography, Row, Col } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

function AboutPage() {
  const { Title } = Typography;
  const { t } = useTranslation();

  return (
    <div style={{ padding: '150px 10% 80px' }}>
      <div className={'text-title'}>{t('About Us')}</div>
      <div className={'text-content'}>
        <div>
          {t(
            'VidioSign is a new type of electronic contract. Throughout their lives, the VidioSign team has realized that writing contracts are too inefficient. It will become a matter of course in the future for paper contracts to be replaced by electronic contracts. However, electronic contracts in the market are still complex. VidioSign has made contract signing just one click away. Another problem with contract writing is false signatures. We ask that you attach a number of additional documents because we do not know if the other party really signed us honestly. VidioSign supports a very secure contract by simply taking a picture of yourself with your mobile phone when creating a signature, storing it as an NFT, and issuing a certificate.'
          )}
        </div>
        <br />

        <div>
          {t(
            'In the future, the VidioSign team will continue to think deeply, develop, and support the easiest and most legally safe way for corporations and individuals to sign contracts. Thank you to all customers who use VidioSign.'
          )}
        </div>
        <div>
          {t(
            '紙による契約書から電子契約書への変化は、すでに当たり前の時代になっていますが、既存の電子契約サービスは、契約締結までの手順が複雑すぎるという問題が多いということを考慮しました。'
          )}
        </div>
        <div>
          {t(
            'Vidio Signは、「誰でも」、「簡単に」、「一クリックで」電子契約を締結することができるようにデザインされています。'
          )}
        </div>
        <br />

        <div>
          {t(
            'また、電子契約書作成においてもう一つの問題として、偽署名があります。既存の電子契約サービスでは、相手が本人が署名したのか確認するために複数の追加書類が必要となりますが、Vidio Signは、署名作成時にパソコン、携帯電話など電子端末機を使って簡単に本人が契約に同意したことを撮影し、動画をNFTに変換してサーバーに保存し、認証書を発行することで、安全かつ確実な契約締結を支援します。'
          )}
        </div>
        <div>
          {t(
            '私たちのチームは、法人や個人が契約書を作成する際に、最も簡単で安価で、法的にも安全で信頼性の高い方法を開発することをミッションとしています。それによって、多くの国々の法人や個人を支援することができます。'
          )}
        </div>
        <br />

        <div>
          {t(
            'そして、Vidio Signをご利用いただいたお客様には常に感謝の意を表します。'
          )}
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
