import React, { Fragment, useState } from 'react';

import axios from 'axios';
import { Menu } from 'antd';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';

import Docu from '../components/views/11_Doc/Docu';
import MyPage from '../components/views/03_MyProfile/MyPage';
import { USER_SERVER } from '../config';
import AdminHeader from '../components/views/00_NavBar/adminHeader';
import ShowDocu from '../components/views/11_Doc/Sections/ShowDocu';
import LandingLogin from '../components/views/10_LandingPage/LandingLogin';
import MakeContract from '../components/views/20_MakeContract/MakeContract';
import HomeSignStamp from '../components/views/30_MakeSignature/HomeSignStamp';
import MyPlan from '../components/views/40_Payment/MyPlan';
import SelectPlan from '../components/views/40_Payment/SelectPlan';
import PayPlan from '../components/views/40_Payment/PayPlan';

import DocIcon from '../assets/images/VideoSign/admin-docs-icon.svg';
import LogoutIcon from '../assets/images/VideoSign/admin-logout.svg';
import HomeIcon from '../assets/images/VideoSign/admin-home-icon.svg';
import SignIcon from '../assets/images/VideoSign/admin-sign-icon.svg';
import videoSignLogoColor from '../assets/images/VideoSign/video-sign-logo-color.svg';
import { useEffect } from 'react';

const InnerRoutes = ({ history }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const [isActive, setActive] = useState('false');

  const [Plan, setPlan] = useState('None');
  const [ValidDate, setValidDate] = useState('');
  const [Ticket, setTicket] = useState(0);
  console.log(user);

  useEffect(() => {
    if (window.try_to_pay) {
      window.try_to_pay = false;
      history.push(`/home/myplan`);
    }
    if (user.userData.plan) {
      setPlan(user.userData.plan);
    } else {
      setPlan('None');
    }

    if (user.userData.valid_date) {
      setValidDate(get_date_only(user.userData.valid_date));
    } else {
      setValidDate('fired');
    }

    if (user.userData.available_ticket) {
      setTicket(user.userData.available_ticket);
    } else {
      setTicket(0);
    }
  }, []);

  const items = [
    {
      label: (
        <Fragment>
          <img src={HomeIcon} alt="Logo" className="logo" width={180} />{' '}
          {t('Home')}
        </Fragment>
      ),
      key: 'dashboard',
    },
    {
      label: (
        <Fragment>
          <img src={DocIcon} alt="Logo" className="logo" width={180} />{' '}
          {t('My Contract List')}
        </Fragment>
      ),
      key: 'doc',
    },
    {
      label: (
        <Fragment>
          <img src={DocIcon} alt="Logo" className="logo" width={180} />{' '}
          {t('My Certifications')}
        </Fragment>
      ),
      key: 'certification',
    },
    {
      label: (
        <Fragment>
          <img src={SignIcon} alt="Logo" className="logo" width={180} />{' '}
          {t('Make New Sign / Stamp')}
        </Fragment>
      ),
      key: 'sign',
    },
    {
      label: (
        <Fragment>
          <img src={SignIcon} alt="Logo" className="logo" width={180} />{' '}
          {t('My Plan')}
        </Fragment>
      ),
      key: 'myplan',
    },
    {
      label: (
        <Fragment>
          <img src={LogoutIcon} alt="Logo" className="logo" width={180} />{' '}
          {t('Logout')}
        </Fragment>
      ),
      key: 'logout',
    },
  ];

  const ToggleClass = () => setActive(!isActive);

  function menuKeyHandler(key) {
    if (key === 'logout') {
      logoutHandler();
    } else if (key === 'certification') {
      history.push(`/home/doc/signed`);
    } else if (key === 'doc') {
      history.push(`/home/doc/signed`);
    } else {
      history.push(key === 'dashboard' ? `/home` : `/home/${key}`);
    }
  }

  function logoutHandler() {
    axios.get(`${USER_SERVER}/logout`).then((response) => {
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: t('Saved!'),
          text: t('Logged out successfully.'),
        });
        history.push('/login');
      } else {
        Swal.fire({
          icon: 'error',
          title: t('Oops...'),
          text: t('Something went wrong, Logout Failed'),
        });
      }
    });
  }

  function get_date_only(tdate) {
    const date = new Date(tdate);
    const dateOnly = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return dateOnly;
  }

  return (
    <div className="dashboardWrap">
      <div className={isActive ? 'adminSideNav' : 'adminSideNav hide'}>
        <div className="adminLogo" align="center">
          <Link to={`/`}>
            <img
              src={'/images/adminLogo.png'}
              alt="Logo"
              className="logo"
              width={180}
            />
          </Link>
          <div className="mobileCloseNav" onClick={ToggleClass}>
            <CloseOutlined />
          </div>
        </div>
        <div
          style={{
            background: 'blue',
            color: 'white',
            margin: '10px',
            padding: '5px',
          }}
          onClick={() => history.push('/home/mypage/priceinfo')}
        >
          Plan: {Plan} <br />
          Valid Date: {ValidDate} <br />
          Remaining: {Ticket}
        </div>
        <div className="adminMenu">
          <Menu items={items} onClick={({ key }) => menuKeyHandler(key)} />
        </div>
      </div>

      <div className="adminRightContent">
        <div className="adminTopNav">
          <div className="adminSideToggle" onClick={ToggleClass}>
            {isActive ? <CloseOutlined /> : <MenuOutlined />}
          </div>
          <div
            className="adminSideToggle adminSideToggleMobile"
            onClick={ToggleClass}
          >
            {isActive ? <MenuOutlined /> : <CloseOutlined />}
          </div>
          <AdminHeader />
        </div>

        <Switch>
          <Route exact path="/:lang/home" component={LandingLogin} />
          <Route
            exact
            path="/:lang/home/mypage/:start_tab"
            component={MyPage}
          />
          <Route
            exact
            path="/:lang/home/make/:evsign/:func/:docuId"
            component={MakeContract}
          />
          <Route
            exact
            path="/:lang/home/show_docu/:docuId"
            component={ShowDocu}
          />
          <Route exact path="/:lang/home/myplan" component={MyPlan} />
          <Route exact path="/:lang/home/select_plan" component={SelectPlan} />
          <Route exact path="/:lang/home/pay_plan/:plan" component={PayPlan} />
          {/* <Route exact path="/:lang/home/outsign/:docuId/:emailenc" component={MainOutStartSign} />
                    <Route exact path="/:lang/home/video_confirm/:docuId/:emailenc" component={MainVideoConfirm} /> */}

          <Route exact path="/:lang/home/doc/:start_tab" component={Docu} />
          {user.isLoaded && (
            <Route exact path="/:lang/home/sign">
              <HomeSignStamp
                UserDbId={{ db: 'User', userId: user.userData._id }}
              />
            </Route>
          )}
        </Switch>
      </div>
    </div>
  );
};

export default InnerRoutes;
