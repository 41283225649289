import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PdfImg01 from '../../../../assets/images/VideoSign/pdfimg02.jpeg';

function VideoContract() {
  const { t } = useTranslation();
  const { Title, Text } = Typography;

  return (
    <div style={{ padding: '150px 10% 80px' }}>
      <div className={'text-title'}>{t('Video Contract')}</div>
      <div className={'text-content'}>
        <div>
          {t(
            'The video contract is a new concept that allows you to sign a contract with a video without preparing a separate contract.  A video contract can be concluded simply as shown in the figure below.'
          )}
        </div>

        <div align="center" style={{ marginBlock: 30 }}>
          <img src={PdfImg01} alt="pdf-img" />
        </div>

        <div>
          {t(
            'Then, how can these video contracts be legally protected? VidioSign will deliver a link to the certificate via email or messenger as shown in the sample below at the same time as signing the video contract. This certificate is stored on the blockchain. This video contract certificate can be downloaded with one click and viewed by you.'
          )}
        </div>
        <div>
          {t(
            'Video contracts are used when you are busy and do not have time to write a contract, or when you need to make a contract or agreement urgently.'
          )}
        </div>
        <div>
          {t(
            "Let's look at some examples where video contracts can be useful."
          )}
        </div>
        <br />

        <div>
          {t(
            "Suppose you have a minor car scratch accident on a road with heavy traffic. Even if you promise to take responsibility for this scratch, the other party will call the police because they can't trust your verbal promise. If you block the road like this, many people will be harmed."
          )}
        </div>
        <div>
          {t(
            'In this case, simply click on the video agreement link on VidioSign and upload a video telling who will be held responsible for the car accident and how. A link will then be sent to you via email or text message.'
          )}
        </div>
        <br />

        <div>
          {t(
            'When it is useful to use a video contract, it is difficult to write a contract because the relationship between the contractors is emotional.'
          )}
        </div>
        <div>
          {t(
            'A typical example is a last will. In fact, it is not legally safe to write a will.'
          )}
        </div>
        <div>
          {t(
            'However, it is not easy to verify the authenticity of the signature after the death of the will.'
          )}
        </div>
        <div>
          {t(
            "If you use the service of VidioSign's “A word of advice to your children,” the whole family will be able to create a video will emotionally and gently."
          )}
        </div>
        <div>
          {t(
            'Before parents die, parents want to say the last thing they want to say to their children, such as telling them to take care of their healt'
          )}
        </div>
        <div>
          {t(
            'If parent leave a video with the contents of property division, etc., this will be issued as a contract certificate and it can be used like a word.'
          )}
        </div>
        <br />

        <div>
          {t(
            'It is emotionally difficult to ask for a contract when lending money to a close friend or family member. In this case, you can laugh lightly and leave a video saying that you lent money to VidioSign. It leaves a light image, but a certificate is issued immediately, so you can use it legally later.'
          )}
        </div>
        <br />

        <div>
          {t(
            'If you need to suddenly write an agreement or contract during a meeting between companies, you can record the meeting itself as a video and upload it. Since a certificate containing this video is created immediately, this certificate becomes a very strong legally protected contract.'
          )}
        </div>
        <br />

        <div>
          {t(
            'Video is a trend that is recognized as stronger evidence than any other evidence in court if there is consent from the other party at the time of recording.'
          )}
        </div>
        <div>
          {t(
            'It is the simplest contract, but the contract that can receive the strongest legal protection is the video contract.'
          )}
        </div>
      </div>
    </div>
  );
}

export default VideoContract;
