import axios from "axios";
import fileDownload from "js-file-download";
import Swal from "sweetalert2";
import { IMG_DISPLAY_MARGIN } from "../utils/size_constants";

// const DOCU_STATUS = Object.freeze({
//     'Ready':        0,
//     'Upload':       1,      // upload done
//     'AddRcpt':      2,      // addrcpt done
//     'SetPos':       3,      // setpos done
//     'Agree':        4,      // 개인정보수집동의
//     'SignAll':      5,      // 모든 서명자가 서명했음을 확인
//     'Completed':    6,      // sign 추가, NFT creation, 앞뒷장 추가된 문서 생성완료
//     'Reject':       7,      // 누군가가 거절했음
//     'Reject_legal': 8,      // 전자서명에 동의안함
// });

// rcptlist에서 current user의 index가 무엇인지 찾음
function get_my_index(docu, user_contact) {
    const rcptlist=docu.recipient_list;
    if (!rcptlist || !user_contact)
        return -1;

    let myindex = -1;
    for (let u=0; u<rcptlist.length; u++) {
        const rcpt = rcptlist[u];
        //console.log('compare: ', rcpt.contact, 'with:', user_contact);
        if (rcpt.method==='email' && rcpt.contact===user_contact.email) {
            //console.log('email match with current user !!!');
            myindex = u;
            break;
        }
        if (rcpt.method==='phone' && rcpt.contact===user_contact.phone) {
            console.log('phone match with current user !!!');
            myindex = u;
            break;
        }
    }

    return myindex;
    // if (myindex>=0) {
    //     // button list를 만듦
    //     setMyIndex(myindex);
    // }
}

function pdf_posinfo_to_image_pos(pdf_posinfo, img_display_height) {
    const page = pdf_posinfo.sign_pos_page;
    const x_pdf = pdf_posinfo.sign_pos_x;
    const y_pdf = pdf_posinfo.sign_pos_y;
    const sign_height_pdf = pdf_posinfo.sign_height;

    const ratio = 1024 / img_display_height;			// 이미지크기와 display의 비율. 이미지좌표를 pdf좌표로 변환시키기 위해 필요. todo: 1024-->natural height로 대체할 것
    const pageheight_with_margin = img_display_height + IMG_DISPLAY_MARGIN*2;

    const x = x_pdf / ratio;
    const y = y_pdf / ratio;
    const sign_height_screen = sign_height_pdf / ratio;
    //console.log('pdf_posinfo_to_image_pos:sign_height_pdf=', sign_height_pdf, sign_height_screen)
    const xpos = x;
    const ypos = y + page * pageheight_with_margin + IMG_DISPLAY_MARGIN;

    return [xpos, ypos, sign_height_screen];
}

// scroll window상의 위치를 page, x_pdf, y_pdf로 바꾸어줌.  x_pdf는 마진이 없는 곳부터 잰 위치임.
// img_display_height: y_pdf와 같은 단위임. 
function image_pos_to_pdf_posinfo(xpos, ypos, sign_height_px, img_display_width, img_display_height, move_mode=false) {
    const pageheight_with_margin = img_display_height + IMG_DISPLAY_MARGIN *2;
    const page = Math.floor(ypos / pageheight_with_margin);
    const y_before_adjust = ypos - page * pageheight_with_margin - IMG_DISPLAY_MARGIN;
    //bugfix20220718 const x = xpos - IMG_DISPLAY_MARGIN;
    const x_before_adjust = xpos;

    // boundary 근처를 click하면 sign이 pdf를 벗어나지 않을 정도로 위치를 보정해서 리턴
    const margin_x_px = 100;
    const margin_y_px = sign_height_px+IMG_DISPLAY_MARGIN;
    const x =  Math.min(img_display_width-margin_x_px, x_before_adjust);
    const y =  Math.min(img_display_height-margin_y_px, y_before_adjust);


    const ratio = 1024 / img_display_height; // 이미지크기와 display의 비율. 이미지좌표를 pdf좌표로 변환시키기 위해 필요. todo: 1024-->natural height로 대체할 것
    const y_pdf = parseInt(y * ratio);
    const x_pdf = parseInt(x * ratio);
    const sign_height = sign_height_px * ratio;
    const pdf_posinfo = {};
    pdf_posinfo.sign_pos_page = page;
    pdf_posinfo.sign_pos_x = x_pdf;
    pdf_posinfo.sign_pos_y = y_pdf;
    if (!move_mode) pdf_posinfo.sign_height = sign_height;

    return pdf_posinfo;
}

// async function onClickDownload(e, docu) {
//     e.preventDefault();
//     e.stopPropagation();

//     // 원래코드
//     console.log('docu=', docu);
//     const res = await axios.post('/api/docu/get_file_from_s3', { file_url: docu.file123_url }, { responseType: "blob" });
//     console.log(res.data);
//     if (!res) {
//         return;
//     }

//     const out_fname = docu.title + '.pdf';
//     fileDownload(res.data, out_fname);
//     console.log("download end");
// }

async function onClickDownload(e, docu) {
    e.preventDefault();
    e.stopPropagation();

    const res0 = await axios.post("/api/docu/prepare_recent_pdf", { docuId:docu._id })
                            .catch((err) => {console.error("ShowDocu:", err.message);});
    if (res0.data.success) {
        console.log(`/api/${res0.data.result}`);
    } else {
        console.log("ShowDocu: Error:", res0.data.errmsg);
        alert("Error: cannot prepare recent pdf:", res0.data.errmsg);
        return;
    }

    //console.log('res0.data.result=', res0.data.result);
    const res = await axios.post('/api/docu/get_file_from_local', { filename: res0.data.result }, { responseType: "blob" });
    //console.log(res.data);
    if (!res) {
        return;
    }

    //const out_fname = docu.title + '.pdf';
    const out_fname = 'final_with_certifications.pdf';
    fileDownload(res.data, out_fname);
    console.log("download end");
}

async function onClickEmail(e, docu, t, lang) {
    e.preventDefault();
    e.stopPropagation();

    console.log('docu=', docu);
    const res_swal = await Swal.fire({
        title: t('Enter email address'),
        input: t('email')
    });

    console.log('res_swal=', res_swal);

    if (res_swal.isConfirmed) {
        const res = await axios.post('/api/docu/send_email_completed_docu', { docuId: docu._id, email: res_swal.value, lang });
        console.log(res.data);
        if (!res) {
            Swal.fire('Fail: Cannot send Email !!!');
            return;
        }
        Swal.fire('Email Sent !!!');
        console.log("send email end");
    }
}

async function onClickDelete(e, docu, refresh, t) {
    e.preventDefault();
    e.stopPropagation();

    console.log('docu=', docu);
    const res_swal = await Swal.fire({
        title: t('Warning'),
        text: t('We do not keep backups. Once deleted, there is no going back. Do you want to delete?'),
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: t("Delete"),
        denyButtonText: t('Deny'),
        cancelButtonText: t('Cancel'),
        customClass: {
            actions: 'my-actions',
            cancelButton: 'order-1 right-gap',
            confirmButton: 'order-2',
            denyButton: 'order-3',
        }
    });

    console.log('res_swal=', res_swal);

    if (res_swal.isConfirmed) {
        const res = await axios.post('/api/docu/remove_docu', { docuId: docu._id });
        console.log(res.data);
        if (!res) {
            Swal.fire('Fail: Cannot remove Document !!!');
            return;
        }

        // if (res.data.result) {
        //     setDocu(res.data.result);
        // }

        refresh();
        await Swal.fire(t('Deleted !!!'));
    }
}

// 이 사용자가 보유한 문서의 리스트와 recent history를 서버에서 가져옴
// ttype=signed/pending
async function get_doculist_signed_pending(ttype) {
    const dataToSubmit = {
        type: ttype
    };
    const res = await axios.post("/api/docu/get_doculist_signed_pending", dataToSubmit);

    if (res.data.success) {
        return res.data.result;
    } else {
        return null;
    }      
}

async function get_docu_history(num_history) {
    const dataToSubmit = {
        num_history
    };
    const res = await axios.post("/api/docu/get_docu_history", dataToSubmit);
    //console.log('res=', res);    

    if (res.data.success) {
        return res.data.result;
    } else {
        return null;
    }      
}

function my_locale_string(d, language){
    const langcode = (language==='Japanese')? 'ja': 'en';
    //console.log('langcode=', language, langcode);

    return d.toLocaleString(langcode, { dateStyle: 'long', timeStyle: 'long', timeZone: 'Asia/Seoul' });        // todo: timezone 설정필요
}

function print_timeinfo(timestamp, t) {
    //const last_timestamp = history[history.length-1].time;
    //const ts10 = parseInt(last_timestamp*0.001);
    //const curr_ts = new Date();
    let diffsec = (new Date().getTime() - timestamp)/1000;
    const dd = parseInt(diffsec/(3600*24));
    diffsec -= dd*(3600*24);
    const hh = parseInt(diffsec/(3600));
    diffsec -= dd*(3600);
    const mm = parseInt(diffsec/(60));
    diffsec -= dd*(60);

    if (dd>0) {
        return `${dd} ${t('days before')}`;
    } else if (hh>0) {
        return `${hh} ${t('hours before')}`;
    } else if (mm>0) {
        return `${mm} ${t('min before')}`;
    } else {
        return `${parseInt(diffsec)} ${t('sec before')}`;
    }
}

// mobile로 찍은 비디오가 있는지 알아차리기 위한 함수 (1sec period로 불림)
async function check_external_video(rcptId, setVideoSRC) {
    const res = await axios.post('/api/docuouter/check_external_video', {rcptId});
    if (res.data.success) {
        console.log('wait')
        if (res.data.vsign_url) {
            console.log('found external recording!')
            setVideoSRC(res.data.vsign_url);
        }
    }
}

const docuutil = {
    get_my_index,
    pdf_posinfo_to_image_pos,
    image_pos_to_pdf_posinfo,
    get_doculist_signed_pending,
    get_docu_history,
    my_locale_string,
    print_timeinfo,
    onClickDownload,
    onClickEmail,
    onClickDelete,
    check_external_video
}

export default docuutil;