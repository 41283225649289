import React, { useState, useEffect, useRef } from "react";
import { useInterval } from 'react-use';
import { withRouter } from "react-router-dom";
import { Button, Typography, Spin } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import MyVideoRecorder from "../../utils/MyVideoRecorder";
import utilfunc from '../../utils/utilfunc';
import { useTranslation } from "react-i18next";
import { PauseOutlined, CaretRightOutlined } from "@ant-design/icons";
import MessageIcon from "../../../assets/images/VideoSign/message-text.svg";
import VsLogo from "../../../assets/images/VideoSign/vsLogo.svg";
import RetakeIcon from "../../../assets/images/VideoSign/retakeIcon.svg";
import PlayIcon from "../../../assets/images/VideoSign/play.png";
import PlayDisabledIcon from "../../../assets/images/VideoSign/play_disabled.jpg";
import PauseIcon from "../../../assets/images/VideoSign/pause.png";



// mobile에서 vsign 촬영만 할 수 있는 페이지
// login 필요없음
function VSignMobile(props) {
	const target_enc = props.match.params.target_enc;
	// needed info = {title, name, vsign_url, rcptId, base_key, MyIndex}
	const { Title } = Typography;
	const { t } = useTranslation();

	const vrecorder = useRef(null);

	const [Updated, setUpdated] = useState(false);
	const [VideoSRC, setVideoSRC] = useState('');
	const [Info, setInfo] = useState([]);
	const [Exist, setExist] = useState(false);
	const [RecordText, setRecordText] = useState('Recording');
	const [PlayText, setPlayText] = useState('disable');
	const [CurrTime, setCurrTime] = useState(Date.now());
	const [isHide, setHide] = useState("false");
	const [Uploading, setUploading] = useState(false);			// to prevent click while uploading
	const [RecStatus, setRecStatus] = useState('ready');			// ready, prepare_rec, rec, prepare_stop

	useInterval(() => {
		setCurrTime(Date.now());
	  }, 1000);

	useEffect(() => {
		async function f() {
			console.log('target_enc=', target_enc);
			const res = await axios.post('/api/docuouter/get_vsign_mobile_info', {target_enc});
			if (res.data.success){
				console.log('result.data=', res.data.result);
				setInfo(res.data.result);
				setVideoSRC(res.data.result.vsign_url);
			}
		}
		f();
	}, []);

	useEffect(() => {
		const exist_cond = (VideoSRC && VideoSRC.length>5);
		setExist(exist_cond);
		if (exist_cond) {
			setRecordText('Retake');
			setPlayText('play');
		} else {
			setRecordText('Recording');
		}
	}, [VideoSRC]);

	useEffect(() => {
		const previewPlayer = document.getElementById('preview');
		const playRecord = document.getElementById('playRecord');

		if (RecStatus==='rec') {
			playRecord.style.display = 'none';
			previewPlayer.style.display = 'block';
		} else {
			playRecord.style.display = 'block';
			previewPlayer.style.display = 'none';
		}
	}, [RecStatus]);


	async function onClickNext() {
		if (Uploading) return;

		if (Updated) {
			setUploading(true);
			const recordedBlob = vrecorder.current.get_recorded_blob();
			const full_s3_key = `${Info.base_key}/vsign-${Info.MyIndex}`;
			const pathname = `${full_s3_key}.mp4`;
			console.log('vsign passname=================', pathname);
			let formData = new FormData();
			formData.append('rcptId', Info.rcptId);
			formData.append('full_s3_key', pathname);
			formData.append('file', recordedBlob, pathname);
			const config = {
				headers: { "Content-type": "multipart/form-data" },
			};
		
			let api_url ="/api/docuouter/uploadVideo";
			const res = await axios.post(api_url, formData, config)
	
			if (res.data.success){
				await Swal.fire(t('Your video has been uploaded successfully. Please continue on the web site.'));
				setUploading(false);
				props.history.push('/');
			} else {
				setUploading(false);
				alert("Error:", res.data.errmsg);
			}
		} else {
			props.history.push('/');
		}
	}

	// start recording
	async function onClickStart() {
		console.log('onClickStart=', RecStatus);
		if (RecStatus!=='ready') {
			return;
		}

		setRecStatus('prepare_rec');
		let have_device = true;
		try {
			const previewPlayer = document.getElementById('preview');
			vrecorder.current = new MyVideoRecorder(previewPlayer);
			await vrecorder.current.openCam();
			await vrecorder.current.start_recording();
			setRecStatus('rec');
			setUpdated(true);
			setPlayText('disable');
		} catch (err) {
			console.log(err.message);	// device가 없어서 error가 난 것으로 추정
			have_device = false;
			setRecStatus('ready');
		}

	}

	// stop recording
	async function onClickStop() {
		console.log('onClickStop=', RecStatus);
		if (RecStatus!=='rec') {
			return;
		}
		setRecStatus('prepare_stop');
		//const previewPlayer = document.getElementById('preview');
		await vrecorder.current.stop_recording();

		for (let k=0; k<30; k++) {
			const vsign_url = vrecorder.current.get_blob_url();
			if (vsign_url==='too_short') {
				setRecStatus('ready');
				setPlayText('play');
				console.log('too short ---------------------------------', k)
				return;
			} else if (vsign_url){
				setVideoSRC(vsign_url);
				setRecStatus('ready');
				setPlayText('play');
				console.log('======', vsign_url, k)
				return;
			}
			await utilfunc.sleep(100);
		}
		//debugger;
		alert('Error');
	}

	async function onClickPlay() {
		const playRecord = document.getElementById('playRecord');
		await playRecord.play();
		setPlayText('pause');
	}

	function onClickPause() {
		const playRecord = document.getElementById('playRecord');
		playRecord.pause();
		setPlayText('play');
	}

	function onEndedPlay() {
		setPlayText('play');
	}


	const ToggleClass = () => setHide(!isHide);
	const [year, month, date, hour, minutes] = utilfunc.get_yyyymmdd(CurrTime);

	return (
	<>
	<div className="dashboardContentWrap afterScan">
		<div className="videoSignWrap">
			<div className="videoImportant">
				<div className="messageIcon" onClick={ToggleClass}>
					<img src={MessageIcon} alt="messages-icon"/>
				</div>
				<div className={isHide ? "importantText" : "importantText hide"}>
					<div className="importantBtn">{t("Important")}</div>
					<Title className="title" level={5}>{t("Please read the following at the beginning of the video recording.")}</Title>
					<div className="importantListWrap">
						<div className="importantList">
							<span>1</span>
							<p>{t("My name is")} <b>{Info.name}</b>.</p>
						</div>
						<div className="importantList">
							<span>2</span>
							<p>{t("Today's date is")} {year}/{month}/{date} {t("and time is")} {hour}:{('0'+minutes).slice(-2)}</p>
						</div>
						<div className="importantList">
							<span>3</span>
							<p>{t("I agree to the conditions mentioned in the contract number")} <b>{Info?.id4?.toUpperCase()}</b></p>
						</div>
					</div>
				</div>
			</div>

			<div className="videoDiv">
				<div className="videoDivInner">
					<div style={{position:"relative"}}>
						<video
							id="preview"
							style={{display:'none'}}
							playsInline
							muted
							loop
						></video>

						<video
							id="playRecord"
							src = {VideoSRC}
							//style={{display:'none'}}
							playsInline
							controls
							onEnded={onEndedPlay}
						></video>
						{RecStatus==='rec' && <div className="blink_me">REC</div>}
					</div>
					<div className="videoBtn">
						<div className="messageIconMobile">
							<div className="messageIcon" onClick={ToggleClass}>
								<img src={MessageIcon} alt="messages-icon"/>
							</div>
							<div className={isHide ? "importantText" : "importantText hide"}>
								<div className="importantBtn">{t("Important")}</div>
								<Title className="title" level={5}>{t("Please read the following at the beginning of the video recording.")}</Title>
								<div className="importantListWrap">
									<div className="importantList">
										<span>1</span>
										<p>{t("My name is")} <b>{Info.name}</b>.</p>
									</div> 
									<div className="importantList">
										<span>2</span>
										<p>{t("Today's date is")} {year}/{month}/{date} {t("and time is")} {hour}:{('0'+minutes).slice(-2)}</p>
									</div>
									<div className="importantList">
										<span>3</span>
										<p>{t("I agree to the conditions mentioned in the contract number")} <b>{Info?.id4?.toUpperCase()}</b></p>
									</div>
								</div>
							</div>
						</div>
						<div className="recordingBtn playPauseBtn">
							{ 
								PlayText==='pause'?    <Button className="btn pauseBtn" onClick={onClickPause}>
															<PauseOutlined />
															<small className="text">{t("Pause")}</small>
														</Button> : 
								PlayText === 'play' ? 	<Button className="btn playBtn" onClick={onClickPlay}> 
															<CaretRightOutlined />
															<small className="text">{t("Play")}</small>
														</Button> : 
														<Button className="btn playBtn" disabled={true}>
															<CaretRightOutlined />
															<small className="text" style={{color:'gray'}}>{t("Play")}</small>
														</Button>
							}
						</div>
						<div className="recordingBtn">
							{ 
								RecStatus==='rec'?                      <Button className="btn rec" onClick={onClickStop}> <small className="text">{t("Stop")}</small></Button> : 
								(RecordText === 'Retake') ? <Button className="btn retakeBtn" onClick={()=>setRecordText('Recording')}> 
                                                				<img src={RetakeIcon} alt="retake-icon" width={28}/> <small className="text">{t("Retake")}</small></Button> : 
								                            <Button className="btn" onClick={onClickStart} disabled={RecStatus==='prepare_rec'}> <small className="text">{t("Record")}</small></Button> 
							}	
						</div>
						<img className="iconOnVideo" src={VsLogo} alt="VS-logo" width={70}/>
					</div>
				</div>
			</div>
		</div>

		<Spin spinning={Uploading}>
			<div className="stepFormBtn videoStepBtn">
				<Button  className="btn nextBtn" onClick={onClickNext} disabled={!Exist || Uploading}> {Updated? t('Upload & Finish') : t('Finish without Change')} </Button>
			</div>
		</Spin>
	</div>
	</>
	);
}

export default withRouter(VSignMobile);
