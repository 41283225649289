import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import './Sections/Navbar.css';
import RightMenu from './Sections/RightMenu';
import videoSignLogo from '../../../assets/images/VideoSign/video-sign-logo.svg';

function NavBar({ language }) {
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const [scroll, setScroll] = useState();

  const str = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  );

  useEffect(() => {
    function onScroll() {
      setScroll(window.scrollY > 50);
    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  return (
    <nav className={scroll ? 'stickyHeader menu' : 'stickyHeader menu'}>
      <div className={str !== '' ? 'innerPageHeader menu-inner' : 'menu-inner'}>
        <div
          style={{
            padding: '0 10%',
          }}
        >
          <Row justify="space-between " className="header-row" align="middle">
            <Col>
              <Link to={`/${language}/`}>
                <img
                  src={videoSignLogo}
                  alt="Logo"
                  className="logo"
                  width={170}
                />
              </Link>
            </Col>
            <Col>
              <RightMenu language={language} />
            </Col>
            {/* <Col>{!user?.userData?.isAuth && <RightMenu language={language} />}</Col> */}
          </Row>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
