import React, { useImperativeHandle, forwardRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import CheckoutForm from "./CheckoutForm";
import { STRIPE_PUBLIC_KEY } from "../../../../config";

/**
 * Make sure to call `loadStripe` outside of a component’s render to avoid
 * recreating the `Stripe` object on every render. Load Public over here.
 * 
 * Guidance Link: https://stripe.com/docs/stripe-js/react
 */
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const StripeMain = ({ clientSecret }, ref) => {

    const mClientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

    const options = {
        // passing the client secret obtained from the server that will be
        // unique/different for very customer & subscription combination
        clientSecret: mClientSecret ?? clientSecret,
        // Fully customizable with appearance API.
        appearance: {
            theme: 'flat'
        }
    };

    useImperativeHandle(ref, () => ({
        someExposedProperty: () => {
            console.log(`we're inside the exposed property function!`);
        },
        someAnotherExposedProperty: () => {
            console.log(`we're inside the someAnotherExposedProperty!`);
        }
    }));

    return (
        <Elements stripe={stripePromise} options={options}>
            <CheckoutForm />
        </Elements>);
};

export default forwardRef(StripeMain);