import React, { useEffect, useState } from "react";
import { Row, Col, Button, Spin, Typography, Radio, Collapse } from "antd";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import Swal from 'sweetalert2';

// called from two components (before login, after login)
function PlansSub(props) {
    const { Text, Title } = Typography;
	const { Panel } = Collapse;
    const { t } = useTranslation();

	const [First, setFirst] = useState(true);
	const [YearMonth, setYearMonth] = useState('year');		// year, month, onetime
	const [Price, setPrice] = useState(null);

	// 가격 정보를 서버에서 가져옴
	useEffect(()=>{
		async function get_price_info() {
			const res = await axios.get("/api/payment/get_price_info");
			if (res.data.success){
				setPrice(res.data.result);
			}
		}
		get_price_info();
	}, []);

	function onChangeYearMonth(e) {
		setFirst(false);
		setYearMonth(e.target.value);
	}

	/////////////////////////////////////// Header Part
	function _year_personal() {
		return (<div>
			<h1>Personal</h1>
			For individuals and sole proprietors with basic e-signature needs <br/>
			${(Price.year_personal.price/1200).toFixed(2)} /month <br/>
			${(Price.year_personal.price/100).toFixed(2)} billed annually
		</div>)
	}

	function _year_standard() {
		return (<div>
			<h1>Standard</h1>
			For small to medium teams that need to send, sign and collaborate <br/>
			${(Price.year_standard.price/1200).toFixed(2)} /month per seat <br/>
			${(Price.year_standard.price/100).toFixed(2)} billed annually
		</div>)
	}

	function _month_personal() {
		return (<div>
			<h1>Personal</h1>
			For individuals and sole proprietors with basic e-signature needs <br/>
			${(Price.month_personal.price/100).toFixed(2)} /month
		</div>)
	}

	function _month_standard() {
		return (<div>
			<h1>Standard</h1>
			For small to medium teams that need to send, sign and collaborate <br/>
			${(Price.month_standard.price/100).toFixed(2)} /month
		</div>)
	}

	function _one_click() {
		return (<div>
			<h1>One Time Ticket</h1>
			For just one contract <br/>
			${(Price.price_onetime.price/100).toFixed(2)}
		</div>)
	}

	///////////////////////////////////// Bottom Part
	function _personal() {
		return (<div>
			INCLUDES	<br/>
			Single seat	<br/>
			5 envelopes per month	<br/>
			Reusable templates	<br/>
		</div>)
	}
	function _standard() {
		return (<div>
			INCLUDES	<br/>
			All Personal Plan features	<br/>
			Up to 50 seats	<br/>
			Shared templates	<br/>
			Collaborative commenting	<br/>
			Customized branding	<br/>
		</div>)
	}
	function _one() {
		return (<div>
			INCLUDES	<br/>
			All Standard Plan features	<br/>
			Up to 5 seats	<br/>

			Forms for Real Estate#	<br/>
			NEW	<br/>
			Signer attachments	<br/>
			Strikethrough	<br/>

			zipForm® Plus Integration†	<br/>
			REALTOR®-logo branding	<br/>
		</div>)
	}

	////////////////////////////////////////
	// function onClickPay(plans) {
	// 	let money_per_seat;
	// 	if (plans==='year_personal'){
	// 		money_per_seat = Price.year_personal * 12;
	// 	} else if (plans==='year_standard'){
	// 		money_per_seat = Price.year_standard * 12;
	// 	} else if (plans==='month_personal'){
	// 		money_per_seat = Price.month_personal;
	// 	} else if (plans==='month_standard'){
	// 		money_per_seat = Price.month_standard;
	// 	} else if (plans==='price_onetime'){
	// 		money_per_seat = Price.price_onetime;
	// 	} 

	// 	props.onClickPay(plans, money_per_seat);
	// }

	////////////////////////////////////////
	function card(header_object, bottom_object, plans) {
		if (!Price) return;

		return (				
			<Col span={5} style={{border:'1px solid gray', height:'440px'}}>
				<Row>
					{header_object()} <br/>
				</Row>
				<Row>
					<Button type='primary' onClick={()=>props.onClickPay(plans)} style={{margin:'20px'}}>Buy Now</Button>
				</Row>
				<Row>
					{bottom_object()}
				</Row>
			</Col>
		)
	}

	//////////////////////////////////////////// FAQ
	const Faqlist = [
		{
			question:'How do I upgrade, downgrade or cancel my plan?',
			answer:'Canceling is easy. To cancel a pricing plan, follow the downgrade plan or close account instructions. You can also upgrade or downgrade to a different plan anytime. To update your plan, follow the change plan instructions.'
		}, 
		{
			question:'Does everyone signing the document need a DocuSign account?',
			answer:'No, recipients of your documents do not need an account to sign with DocuSign. Your document will be sent by email from dse@docusign.net and recipients can review the document, adopt a signature and complete the signing process without having a DocuSign account.'
		}, 
		{
			question:'How do you protect my data?',
			answer:'For information about data protection, visit our data protection page. For information about security, visit our security attachment page.'
		}, 
		{
			question:'How long is the trial period?',
			answer:'The trial period is 30 days.'
		}, 
	];

	const renderCards = Faqlist.map((faq, index) => {
        return (
            <div key={index}>
                <Row justify="start">
                    <Col span={24}>
                        <Collapse bordered={false}>
                            <Panel header={t(faq.question)} key="1">
                                {t(faq.answer)}
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <br />
            </div>
        );
    });

	//////////////////////////////////////////////////////////////////
    return (<div style={{marginTop:'100px'}}>
			<h1>Select Pricing</h1>

			<Radio.Group value={YearMonth} onChange={onChangeYearMonth}>
				<Radio.Button value="year">Annual</Radio.Button>
				<Radio.Button value="month">Monthly</Radio.Button>
				<Radio.Button value="oneclick">OneTime</Radio.Button>
			</Radio.Group>

			{
				First? 
					<Row justify='space-around' style={{marginTop:'100px'}}>
						{card(_year_personal, _personal, 'year_personal')}
						{card(_year_standard, _standard, 'year_standard')}
						{card(_one_click, _one, 'price_onetime')}
					</Row> :
				YearMonth==='year'? 
					<Row justify='space-around' style={{marginTop:'100px'}}>
						{card(_year_personal, _personal, 'year_personal')}
						{card(_year_standard, _standard, 'year_standard')}
					</Row>:
				YearMonth==='month'? 
					<Row justify='space-around' style={{marginTop:'100px'}}>
						{card(_month_personal, _personal, 'month_personal')}
						{card(_month_standard, _standard, 'month_standard')}
					</Row>:
					<Row justify='space-around' style={{marginTop:'100px'}}>
						{card(_one_click, _one, 'price_onetime')}
					</Row>
			}

			<h1>Pricing FAQs</h1>
			{renderCards}

	</div>);
}

export default withRouter(PlansSub);
