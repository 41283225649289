import React, { useState } from "react";
import { Button, Spin, Typography } from "antd";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import UploadIcon from "../../../assets/images/VideoSign/cloud-plus.svg";




// 파일을 선택하는 화면.  파일을 선택하면 확인과정 없이 바로 서버에 업로드.
function ProcUpload(props) {
  const [SpinFlag, setSpinFlag] = useState(false);
  const { Text, Title } = Typography;
  const { t } = useTranslation();

  // file이 drop되거나 선택되면 불림
  async function onDrop(files) {
    setSpinFlag(true);

    const file = files[0];
    const type = file.type;
    if (type!=='application/pdf') {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("Only pdf files are supported in the current version."),
      });
      setSpinFlag(false);
      return;
    }

    if (file.size <= 0 || file.size>50000000) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("Please check if you are trying to upload a file larger than 50MB"),
      });
      setSpinFlag(false);
      return;
    }

    Upload(file)
  }

  // 실제 서버로 upload
  async function Upload(File0) {
    let formData = new FormData();
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    //alert('File0='+File0);
    const use_video_sign = (props.evsign==='vsign');
    formData.append('use_video_sign', use_video_sign);
    formData.append("file", File0);
    let res_data = null;

    // main contents를 upload함
    const res = await axios.post("/api/docu/uploadFile", formData, config).catch(err=>{console.log(err.message); throw err;});

    if (res.data.success) {
      console.log("contents upload success:", res.data);
      const docuId = res.data.docuId;
      setSpinFlag(false);
      props.history.push(`/home/make/${props.evsign}/addrcpt/${docuId}`);
    } else {
      setSpinFlag(false);
      alert(t("Failed to upload file:"+res.data.errmsg));
    }
  }

  // 파일 선택 이전에 보이는 화면
  function BeforeDrop() {
    return (
      <>
        <div className="stepForm">
          <div className="stepFormCard">
            <Title className="formCardTitle" level={5}>{t("Upload your document")}</Title>
            <Dropzone onDrop={onDrop} multiple={false} maxSize={500000000}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                    <div className="uploadPDF">
                        <img src={UploadIcon} alt="Logo" className="notification-icon" /> 
                        <Text>{t("Drag 'n drop files here or click and select the documents")}</Text>
                        <Spin spinning={SpinFlag} size='large'/>
                    </div>
                    <div align="center">
                        <button type='button' className="primaryBtn">{t("Upload...")}</button>
                        <Text className="uploadMsg">{t("Only PDF file formats support")}</Text>
                    </div>
                </div>
              )}
            </Dropzone>
          </div>
        </div>
      </>
    );
  }

  function onClickPrev() {
      props.history.push("/home");
  }

  return (
    <div>
      <BeforeDrop />

      <div className="stepFormBtn">
          <Button onClick={onClickPrev} className="btn preBtn"><ArrowLeftOutlined className="arrowIcon" /> {t("Previous")}</Button>
          <Button disabled={true} className="btn nextBtn">{t("Next")} <ArrowRightOutlined className="arrowIcon" /></Button>
      </div>
    </div>
  );
}

export default withRouter(ProcUpload);
