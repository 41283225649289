import React, { useEffect, useState } from "react";
import { Row, Col, Button, Typography } from "antd";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PlansSub from "./Sub/PlansSub";
import { useSelector } from "react-redux";

// login한 상태에서 plan을 선택하면 보이는 화면
function SelectPlan(props) {
    const { Text, Title } = Typography;
    const { t } = useTranslation();
	const user = useSelector((state) => state.user);

	const [Mode, setMode] = useState('progress');		// progress, plans, payment
	const [ModePayment, setModePayProgress] = useState(true);
	const [Plans, setPlans] = useState('personal');
	const [Price, setPrice] = useState([]);

	function onClickPay(plan) {
		props.history.push(`/home/pay_plan/${plan}`);
	}

	//////////////////////////////////////////////////////////////////
    return (<div>
		<PlansSub onClickPay={onClickPay}/>:
	</div>);
}

export default withRouter(SelectPlan);
