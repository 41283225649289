import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Button, Spin, Typography, Radio, Collapse } from "antd";
import { useLocation, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import Swal from 'sweetalert2';
import PlansSub from "./Sub/PlansSub";

// login 이전에 plan을 선택하면 보이는 화면
function Plans(props) {
	function onClickPay(key) {
		window.try_to_pay = true;
		Swal.fire('You need to Sign In to Buy.');
		props.history.push('/login');
	}

	return (
		<PlansSub onClickPay={onClickPay}/>
	)
}

export default withRouter(Plans);
