import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Row, Button, Tabs, Input, Typography } from "antd";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import PricingInfoPage from "./PricingInfoPage";



function MyInfoPage(props) {
    const { Title } = Typography;
    const { t } = useTranslation();
    const user = useSelector((state) => state.user);

    const [User, setUser] = useState({});

    useEffect(() => {
        getUserById();
    }, [user?.userData?.isAuth]);

    async function getUserById() {
        const res = await axios.post("/api/users/getUserById", { userId: user.userData?._id });
        if (res.data.success) {
            const tresult = res.data.result;
            // console.log('user=', tresult);
            setUser(tresult);
        } else {
            Swal.fire(t('Fail to load user information'));
        }
    }

    async function onClickDeregister() {
        const res_swal = await Swal.fire({
            title: t("Caution!"),
            text: t("If you close account, you cannot signin anymore.  Are you sure?"),
            icon: "question",
            showCancelButton: true,
            cancelButtonText: t("Cancel"),
            confirmButtonText: t("Proceed deregister"),
        });

        if (res_swal.isConfirmed) {
            const res_logout = await axios.post('/api/users/deregister', {});
            if (res_logout.data.success) {
                alert('Your Account is closed successfully');
                // axios.get('/api/users/logout').then((response) => {
                //     if (response.status === 200) {
                //         Swal.fire({ icon: "success", title: t("Saved!"), text: t("Logged out successfully.") });
                //         props.push('/');
                //     } else {
                //         Swal.fire({ icon: "error", title: t("Oops..."), text: t("Something went wrong, Logout Failed") });
                //     }
                // });
        
            }
        }
    }

    return (
        <>
            <div className="myInfoWrap tabContent">
                <Title className="tabTitle" level={2}>{t("My Info")}</Title>
                <Row gutter={12}>
                    <Col xs={24}>
                        <div className="formGroup">
                            <Input className="formControl" value={User.name} placeholder={t("User")} />
                        </div>
                    </Col>
                    <Col xs={24}>
                        {User.email?
                            <div className="formGroup formBtnWrap">            
                                <Input className="formControl" value={User.email} placeholder={t("Email Address")}/>
                                <Button className="formBtn">{t("Verified")}</Button> 
                            </div> :
                            <div className="formGroup formBtnWrap">
                                <Input className="formControl" placeholder={t("Email Address")}/>
                                <Button className="formBtn">{t("Verify")}</Button> 
                            </div>
                        }
                    </Col>
                    {/* <Col lg={24}>
                        {User.phone ?
                            <div className="formGroup formBtnWrap">
                                <Input className="formControl" value={User.phone} placeholder={t("Phone Number")} />
                                <Button className="formBtn">{t("Verified")}</Button>
                            </div> :
                            <div className="formGroup formBtnWrap">
                                <Input className="formControl" placeholder={t("Phone Number")} />
                                <Button className="formBtn">{t("Verify")}</Button>
                            </div>
                        }
                    </Col>
                    <Col lg={24}>
                        <div className="formGroup formBtnWrap">
                            <Input className="formControl" value={User.phone} placeholder={t("Blockchain wallet")} />
                            <Button className="formBtn">{t("Connect")}</Button>
                        </div> 
                    </Col> */}
                    <Col xs={24} align="right">
                        <Button className="primaryBtn">{t("Save")}</Button>
                    </Col>
                </Row>

                <Row>
                    <Button onClick={onClickDeregister}>Close Account</Button>
                </Row>
            </div>
        </>
    )
}

export default withRouter(MyInfoPage);
