import React, { useState, useEffect, useRef } from "react";
import { useInterval } from 'react-use';
import { withRouter } from "react-router-dom";
import { Button, Modal, Typography } from "antd";
import axios from "axios";
import MyVideoRecorder from "../../utils/MyVideoRecorder";
import utilfunc from '../../utils/utilfunc';
import docuutil from '../../utils/DocuUtil';
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined, ArrowRightOutlined, PauseOutlined, CaretRightOutlined } from "@ant-design/icons";
import DeviceImg from "../../../assets/images/VideoSign/deviceImg.svg";
import MessageIcon from "../../../assets/images/VideoSign/message-text.svg";
import VsLogo from "../../../assets/images/VideoSign/vsLogo.svg";
import RetakeIcon from "../../../assets/images/VideoSign/retakeIcon.svg";




function VSign(props) {
	const {rcpt, title, outerFlag, full_s3_key, docuId, id4, MyIndex} = props;
	const vrecorder = useRef(null);
	const { Text, Title } = Typography;
	const { t } = useTranslation();

	//const [VSignUrl, setVSignUrl] = useState('');
	const [Updated, setUpdated] = useState(false);
	const [VideoSRC, setVideoSRC] = useState('');
	const [Exist, setExist] = useState(false);
	const [RecordText, setRecordText] = useState('Recording');
	const [PlayText, setPlayText] = useState('disable');
	const [QR_visible, setQR_visible] = useState(false);
	const [QR_data, setQR_data] = useState([]);
	const [CurrTime, setCurrTime] = useState(Date.now());
	const [isHide, setHide] = useState("false");
	const [CheckExternal, setCheckExternal] = useState(false);
	const [VideoState, setVideoState] = useState('Ready');
	const [RecStatus, setRecStatus] = useState('ready');			// ready, prepare_rec, rec, prepare_stop

	useInterval(() => {
		if (rcpt?._id && !CheckExternal && VideoSRC==='' &&  RecStatus==='ready') {
			setCheckExternal(true);
		}
		setCurrTime(Date.now());
	}, 1000);


	useEffect(() => {		
		const vsign_url = rcpt.vsign_url;
		console.log('vsign_url=', vsign_url);
		//setVSignUrl(vsign_url);
		setVideoSRC(vsign_url);
	}, [rcpt?.vsign_url]);

	useEffect(() => {
		const exist_cond = (VideoSRC && VideoSRC.length>5);
		setExist(exist_cond);
		if (exist_cond) {
			setRecordText('Retake');
			setPlayText('play');
		} else {
			setRecordText('Recording');
		}
	}, [VideoSRC]);

	useEffect(() => {
		const previewPlayer = document.getElementById('preview');
		const playRecord = document.getElementById('playRecord');

		if (RecStatus==='rec') {
			playRecord.style.display = 'none';
			previewPlayer.style.display = 'block';
		} else {
			playRecord.style.display = 'block';
			previewPlayer.style.display = 'none';
		}
	}, [RecStatus]);

	// get qr code
	useEffect(()=>{
		async function f() {
			console.log('rcpt in qrcode=', rcpt);
			const dataToSubmit = {docuId, rcptId:rcpt._id, MyIndex, midurl:'vsign_mobile'};
			const res_qr = await axios.post('/api/docu/get_qr_code', dataToSubmit);
			if (res_qr.data.success) {
				setQR_data(res_qr.data.result);
				console.log('qrcode str=', res_qr.data.result);
				//setQR_visible(true);
			}
		}
		if (docuId && rcpt?._id) f();
	},[docuId, rcpt, MyIndex])

	useEffect(()=>{
		if (CheckExternal){
			docuutil.check_external_video(rcpt._id, setVideoSRC);
			setCheckExternal(false);
		}
	}, [CheckExternal]);

	async function onClickNext() {
		if (Updated) {
			const recordedBlob = vrecorder.current.get_recorded_blob();
			const pathname = `${full_s3_key}.mp4`;
			console.log('vsign passname=================', pathname);
			let formData = new FormData();
			formData.append('rcptId', rcpt._id);
			formData.append('full_s3_key', pathname);
			formData.append('file', recordedBlob, pathname);
			const config = {
				headers: { "Content-type": "multipart/form-data" },
			};
		
			let api_url =(outerFlag)? "/api/docuouter/uploadVideo" : "/api/docu/uploadVideo";
			const res = await axios.post(api_url, formData, config)
	
			if (!res.data.success){
				return;
			}
		}

		props.onClickNext();
	}


	async function onClickStart() {
		console.log('onClickStart=', RecStatus);
		if (RecStatus!=='ready') {
			return;
		}

		setRecStatus('prepare_rec');
		let have_device = true;
		try {
			const previewPlayer = document.getElementById('preview');
	
			vrecorder.current = new MyVideoRecorder(previewPlayer);

			await vrecorder.current.openCam();
			await vrecorder.current.start_recording();
			setRecStatus('rec');
			setUpdated(true);
			setPlayText('disable');
		} catch (err) {
			console.log(err.message);	// device가 없어서 error가 난 것으로 추정
			have_device = false;
			setRecStatus('ready');
		}

		if (!have_device){
			setQR_visible(true);
		}
	}

	// stop recording
	async function onClickStop() {
		console.log('onClickStop=', RecStatus);
		if (RecStatus!=='rec') {
			return;
		}
		setRecStatus('prepare_stop');
		//const previewPlayer = document.getElementById('preview');
		await vrecorder.current.stop_recording();

		for (let k=0; k<30; k++) {
			const vsign_url = vrecorder.current.get_blob_url();
			if (vsign_url==='too_short') {
				setRecStatus('ready');
				setPlayText('play');
				console.log('too short ---------------------------------', k)
				return;
			} else if (vsign_url){
				setVideoSRC(vsign_url);
				setRecStatus('ready');
				setPlayText('play');
				console.log('======', vsign_url, k)
				return;
			}
			await utilfunc.sleep(100);
		}
		//debugger;
		alert('Error');
	}

	async function onClickPlay() {
		const playRecord = document.getElementById('playRecord');
		await playRecord.play();
		setPlayText('pause');
	}

	function onClickPause() {
		const playRecord = document.getElementById('playRecord');
		playRecord.pause();
		setPlayText('play');
	}

	function onEndedPlay() {
		setPlayText('play');
	}


	const ToggleClass = () => setHide(!isHide);
	const [year, month, date, hour, minutes] = utilfunc.get_yyyymmdd(CurrTime);

	return (
	<>
		{/* If you agree, click Recording button, and then read following sentence.

		<Row style={{border:'1px solid black', padding:'10px', margin:'20px'}}>
			I am {rcpt?.name}. I agree contract of which id is ABCD.
		</Row> */}

			<div className="videoSignWrap">
				<div className="videoImportant">
					<div className="messageIcon" onClick={ToggleClass}>
						<img src={MessageIcon} alt="messages-icon"/>
					</div>
					<div className={isHide ? "importantText" : "importantText hide"}>
						<div className="importantBtn">{t("Important")}</div>
						<Title className="title" level={5}>{t("Please read the following at the beginning of the video recording.")}</Title>
						<div className="importantListWrap">
							<div className="importantList">
								<span>1</span>
								<p>{t("My name is")} <b>{rcpt.name}</b>.</p>
							</div>
							<div className="importantList">
								<span>2</span>
								<p>{t("Today's date is")} {year}/{month}/{date} {t("and time is")} {hour}:{('0'+minutes).slice(-2)}</p>
							</div>
							<div className="importantList">
								<span>3</span>
								<p>{t("I agree to the conditions mentioned in the contract number")} <b>{id4?.toUpperCase()}</b></p>
							</div>
						</div>
					</div>
				</div>


				<div className="videoDiv">
					<div className="videoDivInner">
						<div style={{position:"relative"}}>
							<video
								id="preview"
								style={{display:'none'}}
								playsInline
								muted
								loop
							></video>

						<video
							id="playRecord"
							src = {VideoSRC}
							//style={{display:'none'}}
							playsInline
							controls
							onEnded={onEndedPlay}
						></video>
							{RecStatus==='rec' && <div className="blink_me">REC</div>}
						</div>
						<div className="videoBtn">
							<div className="messageIconMobile">
								<div className="messageIcon" onClick={ToggleClass}>
									<img src={MessageIcon} alt="messages-icon"/>
								</div>
								<div className={isHide ? "importantText" : "importantText hide"}>
									<div className="importantBtn">{t('Important')}</div>
									<Title className="title" level={5}>{t("Please read the following at the beginning of the video recording.")}</Title>
									<div className="importantListWrap">
										<div className="importantList">
											<span>1</span>
											<p>{t("My name is")} <b>{rcpt.name}</b>.</p>
										</div> 
										<div className="importantList">
											<span>2</span>
											<p>{t("Today's date is")} {year}/{month}/{date} {t("and time is")} {hour}:{('0'+minutes).slice(-2)}</p>
										</div>
										<div className="importantList">
											<span>3</span>
											<p>{t("I agree to the conditions mentioned in the contract number")} <b>{id4?.toUpperCase()}</b></p>
										</div>
									</div>
								</div>
							</div>
							<div className="recordingBtn playPauseBtn">
								{ 
									PlayText==='pause'?    <Button className="btn pauseBtn" onClick={onClickPause}>
																<PauseOutlined />
																<small className="text">{t("Pause")}</small>
															</Button> : 
									PlayText === 'play' ? 	<Button className="btn playBtn" onClick={onClickPlay}> 
																<CaretRightOutlined />
																<small className="text">{t("Play")}</small>
															</Button> : 
															<Button className="btn playBtn" disabled={true}>
																<CaretRightOutlined />
																<small className="text" style={{color:'#808080'}}>{t("Play")}</small>
															</Button>
								}
							</div>
							<div className="recordingBtn">
							{ 
								RecStatus==='rec'?                      <Button className="btn rec" onClick={onClickStop}> <small className="text">{t("Stop")}</small></Button> : 
								(RecordText === 'Retake') ? <Button className="btn retakeBtn" onClick={()=>setRecordText('Recording')}> 
                                                <img src={RetakeIcon} alt="retake-icon" width={28}/> <small className="text">{t("Retake")}</small></Button> : 
								                            <Button className="btn" onClick={onClickStart} disabled={RecStatus==='prepare_rec'}> <small className="text">{t("Record")}</small></Button> 
							}
							</div>
							<img className="iconOnVideo" src={VsLogo} alt="VS-logo" width={70}/>
						</div>
					</div>
				</div>
				<div className="qrCode">
					<div className="deviceImg">
						<img src={DeviceImg} alt="device-img"/>
					</div>
					<div className="qrCodeImg">
						<img src={QR_data?.url_qrimage} /> 
					</div>
					<Title className="title" level={4}>{t("Mobile Device Recording")}</Title>
					<Text className="text">{t("Start here if you want to record your video on a mobile device.")}</Text>
			   </div>
			</div>
	

		<div className="stepFormBtn videoStepBtn">
			<Button className="btn preBtn" onClick={props.onClickPrev}><ArrowLeftOutlined className="arrowIcon" /> {t("Previous")} </Button>
			<Button  className="btn nextBtn" onClick={onClickNext} disabled={!Exist}> {Updated? t('Upload & Next') : t('Next')} <ArrowRightOutlined className="arrowIcon" /></Button>
		</div>

        <Modal className="recordingLinkPopup customPopup"
            title={t("Recording Link")}
            open={QR_visible}
            centered ={true}
            width={400}
			//closable = {true}
			okText = {t("I see...")}
			cancelText = {t("Cancel")}
			onOk ={()=>{setQR_visible(false)}}
			onCancel ={()=>{setQR_visible(false)}}
        >
            {t("This device doesn't seem to have a camera or microphone to take video.")} <br/>
             {t("If you scan the QR Code below with your smartphone, you will be connected to a site where you can take a video.")} <br/>
             {t("After you finish shooting the video, continue with the rest of the process.")} <br/><br/>

        </Modal>
	</>
	);
}

export default withRouter(VSign);
