import React, { useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { Space, Row, Col, Button, Spin, Typography, Radio, Collapse, Dropdown, Divider, Input } from "antd";
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import Swal from 'sweetalert2';
import PlansSub from "./Sub/PlansSub";
import StripeMain from "./Stripe/StripeMain";
import { useSelector } from "react-redux";

function PayPlan(props) {
	const plans = props.match.params.plan;
	const { userData, isLoaded } = useSelector((state) => state.user);
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search).get('isModalOpen');

	const [Price, setPrice] = useState(null);
	const [ToPay, setToPay] = useState(-1);
	const [MoneyPerSeat, setMoneyPerSeat] = useState(0);
	//const [Plans, setPlans] = useState(plan);
	const [Seat, setSeat] = useState('1');
	const [PriceId, setPriceId] = useState('');
	const [PeriodText, setPeriodText] = useState('');
	//const [YearMonth, setYearMonth] = useState('Annual');
    const [clientSecret, setClientSecret] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(searchParams ?? false);

	// 가격 정보를 서버에서 가져옴
	useEffect(()=>{
		async function get_price_info() {
			const res = await axios.get("/api/payment/get_price_info");
			if (res.data.success){
				setPrice(res.data.result);
			} else {
				alert('Error:'+ res.data.errmsg);
			}
		}
		get_price_info();
	}, []);
	
    useEffect(() => {
        return () => {
            setClientSecret('');
            setIsModalOpen(false);
        };

    }, []);

	useEffect(()=>{
		function get_money_per_seat(plans) {
			let money_per_seat;
			if (plans==='year_personal'){
				money_per_seat = Price.year_personal.price;
				setPeriodText('year');
				setPriceId(Price.year_personal.priceId);
				//setYearMonth('Annual');
			} else if (plans==='year_standard'){
				money_per_seat = Price.year_standard.price;
				setPeriodText('year');
				setPriceId(Price.year_standard.priceId);
				//setYearMonth('Annual');
			} else if (plans==='month_personal'){
				money_per_seat = Price.month_personal.price;
				setPeriodText('month');
				setPriceId(Price.month_personal.priceId);
				//setYearMonth('Monthly');
			} else if (plans==='month_standard'){
				money_per_seat = Price.month_standard.price;
				setPeriodText('month');
				setPriceId(Price.month_standard.priceId);
				//setYearMonth('Monthly');
			} else if (plans==='price_onetime'){
				money_per_seat = Price.price_onetime.price;
				setPeriodText('');
				setPriceId(Price.price_onetime.priceId);
				//setYearMonth('');
			} else {
				money_per_seat = 0;
				setPeriodText('');
				setPriceId('');
				//setYearMonth('');
			}
			return money_per_seat;
		}

		if (!Price) return;

		const money_per_seat = get_money_per_seat(plans);
		setMoneyPerSeat(money_per_seat);
		setToPay(Seat * money_per_seat);
	}, [plans, Seat, Price]);


	const year_month = [
		{
			key:'annual',
			label: 'Annual'
		},
		{
			key:'monthly',
			label: 'Monthly'
		}
	];
	/////////////////////////////////////////////////////////////

	// function onClickYearMonth({key}) {
	// 	if (key==='annual') {
	// 		if (Plans==='month_personal') {
	// 			setPlans('year_personal');
	// 		} else if (Plans==='month_standard') {
	// 			setPlans('year_standard');
	// 		}
	// 	} else if (key==='monthly') {
	// 		if (Plans==='year_personal') {
	// 			setPlans('month_personal');
	// 		} else if (Plans==='year_standard') {
	// 			setPlans('month_standard');
	// 		}
	// 	}
	// }

	function onChangeSeat(e) {
		//debugger;
		const tval = parseInt(e.target.value);
		if (tval<=0) {
			alert('cannot be zero or negative!!!');
			return;
		}
		setSeat(e.target.value);
	}

	// function display_year_option(tPlans) {
	// 	if (tPlans==='year_personal' || tPlans==='year_standard') {
	// 		return (<>
	// 			Billing options  &nbsp;&nbsp;
	// 			<Dropdown menu={{items:year_month, onClick:onClickYearMonth}}>
	// 				<a onClick={(e) => e.preventDefault()}>
	// 				<Space>
	// 					{YearMonth}
	// 					<DownOutlined/>
	// 				</Space>
	// 				</a>
	// 			</Dropdown> <br/><br/>
	// 		</>);
	// 	} else if (tPlans==='month_personal' || tPlans==='month_standard') {
	// 		return (<>
	// 			Billing options  &nbsp;&nbsp;
	// 			<Dropdown menu={{items:year_month, onClick:onClickYearMonth}}>
	// 				<a onClick={(e) => e.preventDefault()}>
	// 				<Space>
	// 					{YearMonth}
	// 					<DownOutlined/>
	// 				</Space>
	// 				</a>
	// 			</Dropdown> <br/><br/>
	// 		</>);
	// 	} else if (tPlans==='price_onetime') {
	// 		return '';
	// 	} else {
	// 		alert('Warning: curr Plans='+tPlans)
	// 	}
	// }

	function display_seat_option(tPlans) {
		if (tPlans==='year_personal' || tPlans==='month_personal') {
			return (<>
				Seats: 1 <br/>
			</>);
		} else if (tPlans==='year_standard' || tPlans==='month_standard') {
			return (<>
				Seats: 
				<Input                 
					onChange={onChangeSeat}
					value={Seat}
					type="number"
				/> <br/>
				<h6>For more than one seat, upgrade your plan</h6>
			</>);
		} else if (tPlans==='price_onetime') {
			return (<>
				Number of Contract to Buy: 
				<Input                 
					onChange={onChangeSeat}
					value={Seat}
					type="number"
				/> <br/>
			</>);
		} else {
			alert('Warning: curr Plans='+tPlans)
		}
	}

	const onClickSubscription = () => {
        if (isLoaded) {
            //const { stripeCustomerId } = userData;
            const dataToSubmit = { priceId:PriceId, userId: userData._id, quantity:parseInt(Seat), unit_price:MoneyPerSeat };
            axios.post("/api/payment/stripe/create-subscription", dataToSubmit).then((response) => {
				console.log('create-subscription response=', response)
                const { data, status } = response;
                if (status === 200) {
                    const { clientSecret } = data;
                    setClientSecret(clientSecret);
                    setIsModalOpen(true);
                } else {
                    alert('onClickSubscription:Unkown error occured!');
                }
            }).catch((err) => {
                console.log('Error:', err.message);
				alert('onClickSubscription:'+err.message);
            });

        } else {
            console.log('User data is not loaded yet!');
        }
    };

	const onClickBuyOnetime = () => {
        if (isLoaded) {
            //const { stripeCustomerId } = userData;
            const dataToSubmit = { priceId:PriceId, userId: userData._id, quantity:parseInt(Seat), unit_price:MoneyPerSeat };
            axios.post("/api/payment/stripe/buy_onetime", dataToSubmit).then((response) => {
				console.log('buy_onetime response=', response)
                const { data, status } = response;
                if (status === 200) {
                    const { clientSecret } = data;
                    setClientSecret(clientSecret);
                    setIsModalOpen(true);
                } else {
                    alert('onClickBuyOnetime:Unkown error occured!');
                }
            }).catch((err) => {
                console.log('Error:', err.message);
				alert('onClickBuyOnetime:'+err.message);
            });

        } else {
            console.log('User data is not loaded yet!');
        }
    };

	return (
        <div style={{marginTop:'100px'}}>
		{
			(isModalOpen)? <StripeMain clientSecret={clientSecret}/> :
			<>
			ORDER SUMMARY
			<h3>{props.personal_standard}</h3> <br/>
			<b>{props.name}</b> <br/>
			{props.email} <br/><br/>

			{/* {display_year_option(Plans)} <br/> */}
			{display_seat_option(plans)}

			<Divider/>

			Subtotal ${(ToPay/100).toFixed(2)}/{PeriodText}  <br/>
			<Divider/>

			Due today 		${(ToPay/100).toFixed(2)} <br/>
			<Divider/>

			We support payments through stripe.<br/>
			<Button onClick={plans==='price_onetime'? onClickBuyOnetime:onClickSubscription}>Subscribe</Button>
			</>
		}
        </div>
    );
}

export default withRouter(PayPlan);
