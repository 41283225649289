import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Layout, Menu, Row } from "antd";

// import AdminSettings from "./AdminSettings";
// import SendEmail from "./SendEmail";
// import UserInfo from "./UserInfo";

function AdminPage() {
    const { Sider, Content } = Layout;
    
    const [Sel, SetSel] = useState('jobs');

    const items = [
        { 
            label: 'Setting', 
            key: "setting",
        },
        { 
            label: 'Jobs',
            key: "jobs", 
        }
    ];

    function onClick(e) {
        console.log('------->', e.key);
        SetSel(e.key);
    }

    return (
        <div>
            <Row  style={{ marginTop:'200px' }}>
            {/* <Title level={2}>{t("Admin Page")}</Title> */}
            {/* <hr /> */}
            <Layout>
                <Sider width={200} style={{overflow:'hidden'}}>
                    <Menu items={items} onClick={onClick} selectedKeys={[Sel]}/>
                </Sider>
                <Content style={{border:'1px solid red' }}>
                    <Row>
                        {Sel === 'setting' ? <div>'setting'</div> :
                                            <div>'jobs'</div>}
                    </Row>
                </Content>
            </Layout>
            </Row>
        </div>
    );
};

export default withRouter(AdminPage);
