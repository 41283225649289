import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import videoSignatureImg from '../../../../assets/images/VideoSign/video-contract-img.jpeg';
import PdfImg01 from '../../../../assets/images/VideoSign/pdfimg01.jpeg';
import SignImg from '../../../../assets/images/VideoSign/sign-img.png';

function VideoSignature() {
  const { t } = useTranslation();

  return (
    <div style={{ padding: '150px 10% 80px' }}>
      <div className={'text-title'}>{t('Video Signature')}</div>

      <div className={'text-content'}>
        <div>
          {t(
            'Video Sign is a contract developed to prevent signature forgery.It is an electronic contract in which the video of the contractor is included in the signature signed on the contract. Video Sign includes the video URL that the contractor agrees to under the signature of the electronic contract below.'
          )}
        </div>

        <div align="center" style={{ marginBlock: 30 }}>
          <img src={SignImg} alt="pdf-img" />
        </div>

        <div>
          {t(
            'And when you sign this contract, the certificate is immediately issued and sent to you via email.'
          )}
        </div>
        <div>
          {t(
            "Signature counterfeiting is the act of signing someone else's signature or tampering with a document in order to commit fraud or defraud the other person.  Signature forgery is a crime, but it happens in too many cases worldwide."
          )}
        </div>
        <br />
        <div>
          {t(
            'Such forgery of signatures occurs in many cases, typically in many contracts, identification documents such as passports, checks, wills, drug prescriptions, deeds, stock certificates, patents, military documents, historical documents, works of art and certificates.'
          )}
        </div>
        <div>
          {t(
            'It is happening extensively. If the signature on the contract is forged, it becomes difficult to protect our legal rights even if the other party claims to invalidate the contract. Also, it is impossible to determine whether the other party forged the contract signature or not.Therefore, the risk of electronic signatures increases.'
          )}
        </div>
        <div>
          {t(
            'For this reason, VidioSign with video is the most secure method of electronic contracts currently available.'
          )}
        </div>
        <br />
        <div>{t('The way to sign a video sign is as follows.')}</div>
        <div align="center" style={{ marginBlock: 30 }}>
          <img
            style={{ maxWidth: '100%' }}
            src={videoSignatureImg}
            alt="pdf-img"
          />
        </div>

        <div>
          {t(
            'After completing the e-contract, you can include a video of the contractor, such as:'
          )}
        </div>
        <div align="center" style={{ marginBlock: 30 }}>
          <img src={PdfImg01} alt="pdf-img" />
        </div>

        <div>
          {t(
            'During video recording, the contractor says his/her name, date and time, and the sentence “I agree to the contract” is recorded together.  A certificate is provided as soon as this video is uploaded.'
          )}
        </div>
        <div>
          {t(
            'In the past, signatures on paper documents have also been exposed to the risk of signature forgery, but recently, signatures on electronic contracts have also been exposed to the risk of signature forgery.'
          )}
        </div>
        <br />

        <div>
          {t(
            'The risk of name forgery is equally occurring. For example, someone spoofs an email even if you deny your own signature, in most cases there is no legal way to prove it.'
          )}
        </div>
        <div>
          {t(
            'In order to solve the risk of signature forgery, in the case of paper contract signatures, it is necessary to check forged signatures.'
          )}
        </div>
        <div>
          {t(
            'It is true that it is difficult to completely verify a forged signature even if you find an expert who can detect forged signatures.'
          )}
        </div>
        <div>
          {t(
            'Even in the case of digital signatures, the risk of forged signatures is the same.'
          )}
        </div>
        <div>
          {t(
            'Since digital signatures are signed non-face-to-face, there is no way to know even if the other party signs falsely. Even if you later find out that this is a false signature, the burden of proof is on the victim. Therefore, digital contracts also have many risks.'
          )}
        </div>
        <br />
        <div>
          {t(
            'A video signature is a way to fundamentally solve the problem of counterfeit signatures. electronic contract'
          )}
        </div>
        <div>
          {t(
            'A method of attaching a video that the signer agrees to when signing in real time and saving it as an NFT is. VidioSign, which applied for and developed a patent for this, uses a mobile phone when a contractor signs. It is to include the video “I agree to this Agreement” in the signature. Then, at the bottom of the signature, this contains the URL of the video stored as an NFT.'
          )}
        </div>
        <div>
          {t(
            'In particular, the video of this contractor cannot be uploaded in the past and can only be stored in real time on the NFT server, so it cannot be used as a forged video by duplicating the video signature with an already created video.'
          )}
        </div>
        <br />

        <div>
          {t(
            'Video signatures can provide stronger legal protection as well as protection against forged signatures.'
          )}
        </div>
        <div>
          {t(
            'I cautiously predict that video signing will become an essential service in the future digital contract and esignature markets.'
          )}
        </div>
      </div>
    </div>
  );
}

export default VideoSignature;
