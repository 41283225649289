import { Typography, Collapse, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

function FaqPage() {
  const { t } = useTranslation();

  const { Title, Text } = Typography;
  const { Panel } = Collapse;

  const [openedTab, setOpenedTab] = useState('0');

  const getTitle = (title, key) => {
    return (
      <div
        style={{
          height: '32px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'transparent',
          fontSize: '16px',
          fontWeight: 'bold',
          color: 'black',
        }}
      >
        <div>{t(title)}</div>
        <img
          src={`/images/${openedTab === key ? 'faq_expand_less' : 'faq_expand_more'}.png`}
          alt={`faq_expand_${key}`}
        />
      </div>
    );
  };

  return (
    <div style={{ padding: '150px 10% 80px' }}>
      <div className="faq__wrapper">
        <Row justify="center">
          <Col lg={24} xs={24}>
            <div className={'text-title'}>{t('Faq')}</div>
          </Col>
          <Col lg={24} xs={24}>
            <Collapse
              bordered={false}
              accordion
              expandIcon={() => {
                return (
                  <div
                    style={{
                      color: '#FF7B52',
                      fontSize: '22px',
                      fontWeight: 'bold',
                    }}
                  >
                    Q
                  </div>
                );
              }}
              onChange={(key) => {
                setOpenedTab(key ?? '');
              }}
            >
              <Panel
                header={getTitle('Why do I need a video signature?', '1')}
                key="1"
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <div
                    style={{
                      paddingRight: '10px',
                      fontSize: '22px',
                      fontWeight: 'bold',
                      color: '#5589AE',
                    }}
                  >
                    A
                  </div>
                  <div style={{ paddingTop: '5px' }}>
                    <Text>
                      {t(
                        'Since Covid-19, many businesses and individuals have started using e-contracts. The biggest problem in electronic contracts is the problem of forged signatures. Since contracts are signed non-face-to-face without meeting each other, there is no way to verify who is signing.'
                      )}
                      <br></br>
                      {t(
                        'The surest way to prevent counterfeit signatures in non-face-to-face electronic contracts is a video signature that includes a video.'
                      )}
                    </Text>
                  </div>
                </div>
              </Panel>
              <Panel
                header={getTitle('What is video evidence?', '2')}
                key="2"
                style={{
                  backgroundColor: '#F2F9FD',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <div
                    style={{
                      paddingRight: '10px',
                      fontSize: '22px',
                      fontWeight: 'bold',
                      color: '#5589AE',
                    }}
                  >
                    A
                  </div>
                  <div style={{ paddingTop: '5px' }}>
                    <Text>
                      {t(
                        'Video evidence is any form of video used as admissible evidence in a court of law. It can be recorded on video home system (VHS) or in digital format. There has been a rise in using both types of video as evidence in court cases.'
                      )}
                    </Text>
                  </div>
                </div>
              </Panel>
              <Panel
                header={getTitle(
                  'Can a video be used as an evidence in court to prove the contract?',
                  '3'
                )}
                key="3"
                style={{
                  backgroundColor: '#F2F9FD',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <div
                    style={{
                      paddingRight: '10px',
                      fontSize: '22px',
                      fontWeight: 'bold',
                      color: '#5589AE',
                    }}
                  >
                    A
                  </div>
                  <div style={{ paddingTop: '5px' }}>
                    <Text>
                      {t(
                        'Yes. Anybody can definitely use the audio and video recordings. Video recordings are admissible in evidence and can be relied upon while reducing the evidence to prove your case. Video recording is one of the strongest evidence to prove the contract.'
                      )}
                    </Text>
                  </div>
                </div>
              </Panel>
              <Panel
                header={getTitle(
                  'All video recordings can be used as an evidence.',
                  '4'
                )}
                key="4"
                style={{
                  backgroundColor: '#F2F9FD',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <div
                    style={{
                      paddingRight: '10px',
                      fontSize: '22px',
                      fontWeight: 'bold',
                      color: '#5589AE',
                    }}
                  >
                    A
                  </div>
                  <div style={{ paddingTop: '5px' }}>
                    <Text style={{ paddingTop: '5px' }}>
                      {t(
                        'No, In order for Video Recording to have legal evidence, three elements are required.'
                      )}
                    </Text>
                    <br></br>
                    <Text>
                      {t(
                        '1) The person to be recorded must consent to the recording.'
                      )}
                    </Text>
                    <br></br>
                    <Text>
                      {t(
                        '2) It must be proven that the contents of the recording have not been manipulated.'
                      )}
                    </Text>
                    <br></br>
                    <Text>
                      {t(
                        '3) The date the video was taken must be stated, or it must be proven that it has not been tampered with.'
                      )}
                    </Text>
                  </div>
                </div>
              </Panel>
              <Panel
                header={getTitle(
                  'Does VidioSign have strong legal force as evidence?',
                  '5'
                )}
                key="5"
                style={{
                  backgroundColor: '#F2F9FD',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <div
                    style={{
                      paddingRight: '10px',
                      fontSize: '22px',
                      fontWeight: 'bold',
                      color: '#5589AE',
                    }}
                  >
                    A
                  </div>
                  <div style={{ paddingTop: '5px' }}>
                    <Text>
                      {t(
                        '1. When making a video electronic contract, confirm that you agree to video shooting.'
                      )}
                    </Text>
                    <br></br>
                    <Text>
                      {t(
                        "2. Videos stored in VidioSign cannot be manipulated because only videos directly shot in VidioSign's program are converted into NFTs."
                      )}
                    </Text>
                    <br></br>
                    <Text>
                      {t(
                        'Therefore, you can prove that this Video is original and untampered with. Videos shot on VidioSign include a TimeStamp on the blockchain that cannot be tampered with, so you can get a clear legal effect on the date the video was taken.'
                      )}
                    </Text>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default FaqPage;
