import utilfunc from "./utilfunc";

class MyVideoRecorder {

    constructor(preview) {
        this.camType = 'user';
        this.preview = preview;
        this.chunks = [];
    }

    async release() {
        delete this.preview.src;
        delete this.preview.srcObject;
        delete this.stream;
    }

    async openCam() {

        try {
            await this.release();
            console.log('before getUserMedia');
            const t0 = performance.now();
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

                // video: { facingMode: { exact: _type } },
                const _stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

                if (_stream) {

                    if ("srcObject" in this.preview) {
                        this.stream = _stream;
                        this.preview.srcObject = _stream;
                        await this.preview.play();
                    }
                }

                const t1 = performance.now();
                console.log('before wait');
                await utilfunc.sleep(600);
                const t2 = performance.now();
                console.log('after wait', t1-t0, t2-t1);
            }

        } catch (err) {
            console.log('MyVideoRecorder:openCam:error:getUserMedia:', err.message);
        }
    }

    consult_possible_codecs() {
        const types = [
            "video/webm",
            "audio/webm",
            "video/webm;codecs=vp8",
            "video/webm;codecs=daala",
            "video/webm;codecs=h264",
            "audio/webm;codecs=opus",
            "video/mpeg",
            "video/mp4",
          ];
          
          for (const type of types) {
            console.log(
              `Is ${type} supported? ${
                MediaRecorder.isTypeSupported(type) ? "Maybe!" : "Nope :("
              }`
            );
          }
    }

    async start_recording() {
        // execute if you want to know whether MediaRecorder can handle specific codec.
        //this.consult_possible_codecs();       

        this.mediaRecorder = new MediaRecorder(this.stream);
        // this.mediaRecorder = new MediaRecorder(this.stream, { mimeType: 'video/mp4;codecs=H264' });
        this.mediaRecorder.ondataavailable = (_event) => {
            //console.log('~~~~~~~~~~~~~~~~~~ondataavailable', _event);
            this.chunks.push(_event.data);
        };
        
        this.mediaRecorder.onstop = (_event) => {
            //console.log('~~~~~~~~~~~~~~~~~~~~onstop', _event, this.chunks);
            // this.blobs = new Blob(this.chunks, { type: _event.target.mimeType });
            this.blobs = new Blob(this.chunks);
            //console.log('blob size=', this.blobs.size);
            if (this.blobs.size<1000) {
                this.url = 'too_short';
            } else {
                this.url = URL.createObjectURL(this.blobs);
            }
            this.stream.getTracks().forEach(track => track.stop()); // stop each of them
        };
        
        this.mediaRecorder.start();
    }

    async stop_recording() {
        await this.mediaRecorder.stop();
        this.preview.pause();
    }

    get_recorded_blob() {
        //console.log('get_recorded_blob', this.blobs);
        return this.blobs;
    }

    get_blob_url() {
        //console.log('get_blob_url', this.url);
        return this.url;
    }
};

export default MyVideoRecorder;
