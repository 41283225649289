import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

// Home for logged-in user
function LandingNotLogin(props) {
  const lang = window.location.pathname.split('/')[1];

  return (
    <div className="homePageWraper">
      {/* First Section */}
      <section style={{ padding: '120px 0 50px' }}>
        <div style={{ padding: '30px 10% 0' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <div
                style={{
                  fontSize: '44px',
                  color: '#004166',
                  fontWeight: 'bold',
                }}
              >
                <div>NFTサインが</div>
                <div>電子契約をもっと便利に</div>
              </div>
              <div
                style={{
                  padding: '30px 0',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                <div>偽造など書き換え不可能な</div>
                <div>“NFT”を活用した電子サインサービスです。</div>
                <div>ビデオ署名での本人確認も可能。</div>
                <div>ワンクリックで簡単に、強力な証明書を作成できます。</div>
              </div>
              <NavLink
                exact
                className="c-nav-link"
                to={`/${lang}/register`}
                style={{
                  color: 'white',
                  cursor: 'pointer',
                  fontSize: '16px',
                  backgroundColor: '#FF7B52',
                  border: 'none',
                  padding: '10px 20px',
                  borderRadius: '4px',
                }}
              >
                30日間無料トライアル
              </NavLink>
              <div
                style={{
                  padding: '30px 0 0 0',
                  display: 'flex',
                  justifyContent: 'start',
                  color: '#5589AE',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  gap: '10px',
                }}
              >
                <div
                  style={{
                    boxShadow: '4px 4px 30px rgb(0 0 0 / .1)',
                    borderRadius: '200px',
                    width: '157px',
                    height: '157px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <div>サービス展開</div>
                  <div>電子契約</div>
                  <div>ビデオ署名</div>
                  <div>ビデオ契約</div>
                </div>
                <div
                  style={{
                    boxShadow: '4px 4px 30px rgb(0 0 0 / .1)',
                    borderRadius: '200px',
                    width: '157px',
                    height: '157px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div>ワンクリックで</div>
                  <div>署名をシンプルに</div>
                </div>
                <div
                  style={{
                    boxShadow: '4px 4px 30px rgb(0 0 0 / .1)',
                    borderRadius: '200px',
                    width: '157px',
                    height: '157px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div>正真性を高める</div>
                  <div>ビデオ署名</div>
                </div>
              </div>
            </div>
            <div>
              <img src={'/images/home1.png'} width={'100%'} alt={'landing1'} />
            </div>
          </div>
          <div
            style={{
              textAlign: 'center',
              color: '#004166',
              fontSize: '18px',
              paddingTop: '30px',
            }}
          >
            <div>Scroll</div>
            <img src={'/images/left_arrow.png'} alt={'landing2'}></img>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section>
        <div
          style={{
            position: 'relative',
          }}
        >
          <img
            src={'/images/home_background1.png'}
            alt={'background1'}
            style={{
              position: 'absolute',
              left: '50%',
              top: '50px',
              zIndex: -2,
            }}
          />
          <img
            src={'/images/home_background2.png'}
            alt={'background1'}
            style={{
              position: 'absolute',
              left: '50%',
              top: '200px',
              zIndex: '-1',
            }}
          />
          {/* 01 */}
          <div
            style={{
              width: '100%',
              padding: '50px 10%',
              color: 'white',
              backgroundColor: '#5589AE',
              opacity: '.9',
            }}
          >
            <div style={{ fontSize: '18px' }}>Service</div>
            <div
              style={{
                color: '#6799BC',
                fontSize: '72px',
                fontWeight: '400',
                lineHeight: '86px',
                letterSpacing: '0em',
              }}
            >
              01
            </div>
            <div style={{ fontWeight: 'bold', fontSize: '40px' }}>
              ワンクリック署名で契約書がNFTに
            </div>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '16px',
                padding: '20px 0',
              }}
            >
              <div>
                もう他の複雑で理解に時間のかかる電子契約サービスはお忘れ下さい。
              </div>
              <div>ワンクリックで電子署名が完了します。</div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'start',
                fontSize: '18px',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
            >
              <div style={{ paddingRight: '10px' }}>もっと見る</div>
              <img
                src={'/images/chevron_right.svg'}
                alt={'chevron_1'}
                style={{
                  filter:
                    'invert(100%) sepia(0%) saturate(0%) hue-rotate(128deg) brightness(100%) contrast(101%)',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: 'black',
                padding: '30px 0',
              }}
            >
              <div
                style={{
                  width: '30%',
                  objectFit: 'cover',
                  borderRadius: '15px',
                  boxShadow: '4px 4px 20px rgb(0 0 0 / .1)',
                }}
              >
                <img
                  width={'100%'}
                  src={'/images/home_service1_1.png'}
                  alt={'service1_1'}
                  style={{ borderRadius: '15px 15px 0 0' }}
                />
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '0 0 15px 15px',
                    padding: '0 10%',
                    height: '100px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  VidioSignは、電子契約書をメールまたはSMSで送信します。
                </div>
              </div>
              <div
                style={{
                  width: '30%',
                  objectFit: 'cover',
                  borderRadius: '15px',
                  boxShadow: '4px 4px 20px rgb(0 0 0 / .1)',
                }}
              >
                <img
                  width={'100%'}
                  src={'/images/home_service1_2.png'}
                  alt={'service1_1'}
                  style={{ borderRadius: '15px 15px 0 0' }}
                />
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '0 0 15px 15px',
                    padding: '0 10%',
                    height: '100px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  「同意する」ボタンを押し、案内に従って契約を完了させて下さい。
                </div>
              </div>
              <div
                style={{
                  width: '30%',
                  objectFit: 'cover',
                  borderRadius: '15px',
                  boxShadow: '4px 4px 20px rgb(0 0 0 / .1)',
                }}
              >
                <img
                  width={'100%'}
                  src={'/images/home_service1_3.png'}
                  alt={'service1_1'}
                  style={{ borderRadius: '15px 15px 0 0' }}
                />
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '0 0 15px 15px',
                    padding: '0 10%',
                    height: '100px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  契約書とNFT証明書が、全ての契約者に送信されます。
                </div>
              </div>
            </div>
          </div>
          {/* 02 */}
          <div
            style={{
              width: '100%',
              padding: '50px 10%',
              color: 'black',
              backgroundColor: 'white',
              opacity: '1',
            }}
          >
            <div style={{ fontSize: '18px', color: '#5589AE' }}>Service</div>
            <div
              style={{
                color: '#ECECEC',
                fontSize: '72px',
                fontWeight: '400',
                lineHeight: '86px',
                letterSpacing: '0em',
              }}
            >
              02
            </div>
            <div
              style={{ fontWeight: 'bold', fontSize: '40px', color: '#004166' }}
            >
              ビデオ署名
            </div>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '16px',
                padding: '20px 0',
              }}
            >
              <div>
                契約書にサインする際に、契約者が契約内容に同意していることを示す動画を含む署名のことです。
              </div>
              <div>
                ビデオ署名は、偽造署名のリスクを根本的にブロックするものです。
              </div>
              <div>電子署名の下部に動画へのリンクが記載されます。</div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'start',
                fontSize: '18px',
                fontWeight: 'bold',
                color: '#004166',
                cursor: 'pointer',
              }}
            >
              <div style={{ paddingRight: '10px' }}>もっと見る</div>
              <img
                src={'/images/chevron_right.svg'}
                alt={'chevron_2'}
                style={{
                  filter:
                    'invert(17%) sepia(22%) saturate(5698%) hue-rotate(179deg) brightness(99%) contrast(101%)',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: 'black',
                padding: '30px 0',
              }}
            >
              <div
                style={{
                  width: '30%',
                  objectFit: 'cover',
                  borderRadius: '15px',
                  boxShadow: '4px 4px 20px rgb(0 0 0 / .1)',
                }}
              >
                <img
                  width={'100%'}
                  src={'/images/home_service2_1.png'}
                  alt={'service2_1'}
                  style={{ borderRadius: '15px 15px 0 0' }}
                />
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '0 0 15px 15px',
                    padding: '0 10%',
                    height: '100px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  VidioSignは、電子契約書をメールまたはSMSで送信します。
                </div>
              </div>
              <div
                style={{
                  width: '30%',
                  objectFit: 'cover',
                  borderRadius: '15px',
                  boxShadow: '4px 4px 20px rgb(0 0 0 / .1)',
                }}
              >
                <img
                  width={'100%'}
                  src={'/images/home_service2_2.png'}
                  alt={'service2_1'}
                  style={{ borderRadius: '15px 15px 0 0' }}
                />
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '0 0 15px 15px',
                    padding: '0 10%',
                    height: '100px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  「同意する」ボタンを押し、案内に従って契約を完了させて下さい。
                </div>
              </div>
              <div
                style={{
                  width: '30%',
                  objectFit: 'cover',
                  borderRadius: '15px',
                  boxShadow: '4px 4px 20px rgb(0 0 0 / .1)',
                }}
              >
                <img
                  width={'100%'}
                  src={'/images/home_service2_3.png'}
                  alt={'service3_1'}
                  style={{ borderRadius: '15px 15px 0 0' }}
                />
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '0 0 15px 15px',
                    padding: '0 10%',
                    height: '100px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  契約書とNFT証明書が、全ての契約者に送信されます。
                </div>
              </div>
            </div>
          </div>
          {/* 03 */}
          <div style={{ position: 'relative' }}>
            <img
              src={'/images/home_background3.png'}
              alt={'background3'}
              style={{
                position: 'absolute',
                right: '0',
                top: '50px',
                zIndex: '-1',
              }}
            />
            <div
              style={{
                width: '100%',
                padding: '50px 10%',
                color: '#353535',
                backgroundColor: '#FF855F',
                opacity: '.9',
              }}
            >
              <div style={{ fontSize: '18px', color: '#FFFFFF' }}>Service</div>
              <div
                style={{
                  color: '#FF9D7E',
                  fontSize: '72px',
                  fontWeight: '400',
                  lineHeight: '86px',
                  letterSpacing: '0em',
                }}
              >
                03
              </div>
              <div
                style={{ fontWeight: 'bold', fontSize: '40px', color: 'white' }}
              >
                ビデオ契約
              </div>
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  padding: '20px 0',
                }}
              >
                <div>
                  契約書を作成する手間をなくしてスマホやパソコンを使って動画を撮影することで、ビデオ契約を結ぶことができます。
                </div>
                <div>
                  撮影された動画は即時NFTに変換、証明書が発行され、すべての契約者のメールアドレスに送信されます。
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'start',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  height: '100%',
                }}
              >
                <div>もっと見る</div>
                <img
                  src={'/images/chevron_right.svg'}
                  alt={'chevron_3'}
                  style={{
                    filter:
                      'invert(15%) sepia(0%) saturate(6%) hue-rotate(157deg) brightness(100%) contrast(83%)',
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  color: 'black',
                  padding: '30px 0',
                }}
              >
                <div
                  style={{
                    width: '30%',
                    objectFit: 'cover',
                    borderRadius: '15px',
                    boxShadow: '4px 4px 20px rgb(0 0 0 / .1)',
                  }}
                >
                  <img
                    width={'100%'}
                    src={'/images/home_service3_1.png'}
                    alt={'service3_1'}
                    style={{ borderRadius: '15px 15px 0 0' }}
                  />
                  <div
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '0 0 15px 15px',
                      padding: '0 10%',
                      height: '100px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    VidioSignは、電子契約書をメールまたはSMSで送信します。
                  </div>
                </div>
                <div
                  style={{
                    width: '30%',
                    objectFit: 'cover',
                    borderRadius: '15px',
                    boxShadow: '4px 4px 20px rgb(0 0 0 / .1)',
                  }}
                >
                  <img
                    width={'100%'}
                    src={'/images/home_service3_2.png'}
                    alt={'service3_2'}
                    style={{ borderRadius: '15px 15px 0 0' }}
                  />
                  <div
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '0 0 15px 15px',
                      padding: '0 10%',
                      height: '100px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    「同意する」ボタンを押し、案内に従って契約を完了させて下さい。
                  </div>
                </div>
                <div
                  style={{
                    width: '30%',
                    objectFit: 'cover',
                    borderRadius: '15px',
                    boxShadow: '4px 4px 20px rgb(0 0 0 / .1)',
                  }}
                >
                  <img
                    width={'100%'}
                    src={'/images/home_service3_3.png'}
                    alt={'service3_3'}
                    style={{ borderRadius: '15px 15px 0 0' }}
                  />
                  <div
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '0 0 15px 15px',
                      padding: '0 10%',
                      height: '100px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    契約書とNFT証明書が、全ての契約者に送信されます。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Case Section*/}
      <section>
        <div
          style={{
            padding: '50px 10% 70px',
            color: '#004166',
          }}
        >
          <div
            style={{
              color: '#ECECEC',
              fontSize: '64px',
            }}
          >
            Case Study
          </div>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '40px',
            }}
          >
            活用事例
          </div>
          <div style={{ fontSize: '18px', fontWeight: 'bold' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '15px 0',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                  border: '1px solid #004166',
                  borderRadius: '4px',
                  width: '20%',
                  padding: '15px 0',
                }}
              >
                <img
                  src={'/images/chevron_right.svg'}
                  alt={'case1'}
                  style={{ paddingRight: '10px', visibility: 'hidden' }}
                />
                <div>人事系</div>
                <img
                  src={'/images/chevron_right.svg'}
                  alt={'case2'}
                  style={{ paddingRight: '10px' }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                  border: '1px solid #004166',
                  borderRadius: '4px',
                  width: '20%',
                  padding: '15px 0',
                }}
              >
                <img
                  src={'/images/chevron_right.svg'}
                  alt={'case3'}
                  style={{ paddingRight: '10px', visibility: 'hidden' }}
                />
                <div>売買系</div>
                <img
                  src={'/images/chevron_right.svg'}
                  alt={'case4'}
                  style={{ paddingRight: '10px' }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                  border: '1px solid #004166',
                  borderRadius: '4px',
                  width: '20%',
                  padding: '15px 0',
                }}
              >
                <img
                  src={'/images/chevron_right.svg'}
                  alt={'case5'}
                  style={{ paddingRight: '10px', visibility: 'hidden' }}
                />
                <div>賃貸借系</div>
                <img
                  src={'/images/chevron_right.svg'}
                  alt={'case6'}
                  style={{ paddingRight: '10px' }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                  border: '1px solid #004166',
                  borderRadius: '4px',
                  width: '20%',
                  padding: '15px 0',
                }}
              >
                <img
                  src={'/images/chevron_right.svg'}
                  alt={'case7'}
                  style={{ paddingRight: '10px', visibility: 'hidden' }}
                />
                <div>営業・購買系</div>
                <img
                  src={'/images/chevron_right.svg'}
                  alt={'case8'}
                  style={{ paddingRight: '10px' }}
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '10px 0',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                  border: '1px solid #004166',
                  borderRadius: '4px',
                  width: '20%',
                  padding: '15px 0',
                }}
              >
                <img
                  src={'/images/chevron_right.svg'}
                  alt={'case1'}
                  style={{ paddingRight: '10px', visibility: 'hidden' }}
                />
                <div>金銭貸借系</div>
                <img
                  src={'/images/chevron_right.svg'}
                  alt={'case1'}
                  style={{ paddingRight: '10px' }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                  border: '1px solid #004166',
                  borderRadius: '4px',
                  width: '20%',
                  padding: '15px 0',
                }}
              >
                <img
                  src={'/images/chevron_right.svg'}
                  alt={'case1'}
                  style={{ paddingRight: '10px', visibility: 'hidden' }}
                />
                <div>業務委託・請負系</div>
                <img
                  src={'/images/chevron_right.svg'}
                  alt={'case1'}
                  style={{ paddingRight: '10px' }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                  border: '1px solid #004166',
                  borderRadius: '4px',
                  width: '20%',
                  padding: '15px 0',
                }}
              >
                <img
                  src={'/images/chevron_right.svg'}
                  alt={'case1'}
                  style={{ paddingRight: '10px', visibility: 'hidden' }}
                />
                <div>その他</div>
                <img
                  src={'/images/chevron_right.svg'}
                  alt={'case1'}
                  style={{ paddingRight: '10px' }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                  border: '1px solid #004166',
                  borderRadius: '4px',
                  width: '20%',
                  padding: '15px 0',
                }}
              >
                <img
                  src={'/images/chevron_right.svg'}
                  alt={'case1'}
                  style={{ paddingRight: '10px', visibility: 'hidden' }}
                />
                <div>人事系</div>
                <img
                  src={'/images/chevron_right.svg'}
                  alt={'case1'}
                  style={{ paddingRight: '10px' }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  Last Section */}
      <section>
        <div
          style={{
            backgroundColor: '#004166',
            padding: '70px 0',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              color: 'white',
            }}
          >
            <div
              style={{
                fontSize: '40px',
                fontWeight: 'bold',
              }}
            >
              今すぐお試しください
            </div>
            <div
              style={{
                fontSize: '24px',
                padding: '10px 0 15px',
              }}
            >
              映像を含む電子契約の締結が簡単にできます
            </div>
            <div
              style={{
                padding: '20px 0 0',
              }}
            >
              <NavLink
                exact
                className="c-nav-link"
                to={`/${lang}/register`}
                style={{
                  cursor: 'pointer',
                  fontSize: '26px',
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: '#FF7B52',
                  border: 'none',
                  padding: '10px 30px',
                  borderRadius: '4px',
                }}
              >
                30日間無料トライアル
              </NavLink>
            </div>
            <div
              style={{
                fontSize: '16px',
                padding: '10px 0',
              }}
            >
              ※クレジットカードの登録は不要です
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default withRouter(LandingNotLogin);
