import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Button, Spin, Typography, Input, Collapse } from "antd";
import { useLocation, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import Swal from 'sweetalert2';
import utilfunc from "../../../utils/utilfunc";
import docuutil from "../../../utils/DocuUtil";

// login 이전에 plan을 선택하면 보이는 화면
function PricingInfoPage(props) {
	const { t } = useTranslation();
	const { i18n } = useTranslation('Home');
    const user = useSelector((state) => state.user);

    const [User, setUser] = useState({});
	const [CompanyPaymentId, setCompanyPaymentId] = useState('');
	const [InvoiceList, setInvoiceList] = useState([]);
	const [LatestSubscription, setLatestSubscription]= useState([]);
	const [CompanyMember, setCompanyMember] = useState([]);

    useEffect(() => {
        getUserById();
    }, [user?.userData?.isAuth]);

    async function getUserById() {
		let tUser;
        const res0 = await axios.post("/api/users/getUserById", { userId: user.userData?._id });
        if (res0.data.success) {
            tUser = res0.data.result;
            // console.log('user=', tUser);
            setUser(tUser);

			// company member관련
			if (tUser.company_payment) {
				const company_payment = await get_company_payment(tUser.company_payment);
				setCompanyPaymentId(company_payment._id);
				setCompanyMember(company_payment.members);
			}
        } else {
            Swal.fire(t('Fail to load user information'));
			return;
        }

		// invoice가 있다면 보여줌
		const res1 = await axios.post("/api/users/get_invoice_list", { customerId: tUser.customerId });
		if (res1.data.success) {
			const tresult = res1.data.result;
			setInvoiceList(tresult);
		} else {
			Swal.fire(t('Fail to load invoice list:' + res1.data.errmsg));
			return;
		}

		// subscription이 있다면 보여줌
		const res2 = await axios.post("/api/users/get_latest_subscription", { customerId: tUser.customerId });
		if (res2.data.success) {
			const tresult = res2.data.result;
			setLatestSubscription(tresult);
		} else {
			Swal.fire(t('Fail to load subscription list:' + res1.data.errmsg));
			return;
		}
    }

	async function get_company_payment(company_paymentId) {
		const res = await axios.post("/api/users/get_company_payment", { company_paymentId: company_paymentId });
		if (res.data.success) {
			return add_blank_to_company_payment(res.data.result);
		} else {
			Swal.fire(t('Fail to load invoice list:' + res.data.errmsg));
			return [];
		}
	}

	async function onClickUnsubscription(subscriptionId, cancel_at_period_end) {
		const res = await axios.post("/api/payment/stripe/cancel-subscription", { subscriptionId, cancel_at_period_end });
		if (res.data.success) {
			Swal.fire(t('Success !!!'));
		} else {
			Swal.fire(t('Fail to load invoice list:' + res.data.errmsg));
		}
		window.location.reload();
	}

	function onChangeInputMember(index, val) {
		CompanyMember[index].email = val;
		CompanyMember[index].updated = true;
		const newlist = [...CompanyMember.slice(0, index), CompanyMember[index], ...CompanyMember.slice(index + 1, CompanyMember.length)];

		setCompanyMember(newlist);
	}

	function add_blank_to_company_payment(company_payment){
		const current_actual_num_member = company_payment.members.length;
		const max_num_member = company_payment.quantity;
		// 빈칸을 붙여서 members의 길이가 quantity가 되도록 만든다.
		for (let k=0; k<max_num_member-current_actual_num_member; k++){
			company_payment.members.push({});
		}
		// updated field를 만들어서 붙임
		for (let k=0; k<max_num_member; k++){
			company_payment.members[k].updated = false;
		}

		//console.log('get_company_payment=', company_payment);
		return company_payment;
	}

	async function onClickUpdate(index) {
		const dataToSubmit ={
			company_paymentId: CompanyPaymentId, 
			member_idx : index,
			new_email : CompanyMember[index].email.trim()
		};
		const res = await axios.post("/api/users/update_company_payment_member", dataToSubmit);
		if (res.data.success) {
			const company_payment = await get_company_payment(CompanyPaymentId);
			setCompanyPaymentId(company_payment._id);
			setCompanyMember(company_payment.members);
			Swal.fire('Success');
			// return add_blank_to_company_payment(res.data.result);
		} else {
			Swal.fire(t('Fail to update:' + res.data.errmsg));
		}
		window.location.reload();
	}

	function ts2date(ts){
		return docuutil.my_locale_string(new Date(ts * 1000), i18n.language);
	}
	
	function show_subscription() {
		// if (LatestSubscription.items.total_count>1) {
		// 	alert('Cannot Process Multi-items');
		// }
		// const item = LatestSubscription.items.data[0];
		// const priceId = item.price.id;

		

		if (LatestSubscription) {
			if (!LatestSubscription.cancel_at){
				return (<>
					created: {ts2date(LatestSubscription.created)}		<br/>
					currency: {LatestSubscription.currency}		<br/>
					current_period_start:{ts2date(LatestSubscription.current_period_start)}		<br/>
					current_period_end:{ts2date(LatestSubscription.current_period_end)}		<br/>
					quantity: {LatestSubscription.quantity}		<br/>
					latest invoice: {LatestSubscription.latest_invoice}		<br/>
					status: {LatestSubscription.status}		<br/>
					<Button disabled={true} onClick={()=>onClickUnsubscription(LatestSubscription.id, false)} size='small'>Unsubscribe Immediately</Button>
					<Button onClick={()=>onClickUnsubscription(LatestSubscription.id, true)} size='small'>Unsubscribe at Period End</Button>
				</>);
			} else {
				return (<>
					Unsubscribe has been scheduled.   <br/>
					created: {ts2date(LatestSubscription.created)}		<br/>
					cancel at: {ts2date(LatestSubscription.cancel_at)}		<br/>
					canceled_at: {ts2date(LatestSubscription.canceled_at)}		<br/>
					currency: {LatestSubscription.currency}		<br/>
					current_period_start:{ts2date(LatestSubscription.current_period_start)}		<br/>
					current_period_end:{ts2date(LatestSubscription.current_period_end)}		<br/>
					quantity: {LatestSubscription.quantity}		<br/>
					latest invoice: {LatestSubscription.latest_invoice}		<br/>
					status: {LatestSubscription.status}		<br/>
				</>);
			}
		} else {
			return (<>
				'No Active Subscription'
			</>);
		}
	}

	function show_invoice() {
		return (<>
			<table style={{ width: '100%' }}>
				<thead>
					<tr>
						<th>Index</th>
						<th>Description</th>
						<th>From</th>
						<th>To</th>
						<th>Invoice Url</th>
						<th>Invoice Pdf</th>
						<th>Invoice ID</th>
					</tr>
				</thead>
				<tbody>
					{render_invoice}
				</tbody>
			</table>
		</>)
	}

	function show_company_members() {
		return (<>
			<table style={{ width: '100%' }}>
				<thead>
					<tr>
						<th>Index</th>
						<th>email</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{render_members}
				</tbody>
			</table>
		</>)
	}

	const render_invoice = InvoiceList && InvoiceList.map(function(invoice, index){
        // console.log('------------------>', invoice_db);
		// const invoice = invoice_db.invoice;
		const lines = invoice.lines;
		if (lines.total_count>1) {
			alert('Warning: line is larger than 1 in invoice' + invoice.id);
		}

		const line_data = lines.data[0];
        return (
            <tr key={index}>
                <td>{index+1}</td>
				<td>{line_data.description}</td>
                <td>{ts2date(line_data.period.start)}</td>
                <td>{ts2date(line_data.period.end)}</td>
                <td><a href={invoice.hosted_invoice_url}> Invoice </a></td>
                <td><a href={invoice.invoice_pdf}> Download </a></td>
				<td> {invoice.id} </td>
            </tr>
        );
		}
    );

	const render_members = CompanyMember && CompanyMember.map(function(member, index){
		// const invoice = invoice_db.invoice;
        return (
            <tr key={index}>
                <td>{index+1}</td>
				<td> <Input value={member.email} placeholder={'add email'} onChange={(e)=>onChangeInputMember(index, e.currentTarget.value)}/></td>
                <td>
					<Button size='small' disabled={!member.updated} onClick={()=>onClickUpdate(index)}>Update</Button> 
				</td>
            </tr>
        );
		}
    );

	return (
		<div>		
			{
				<Row>
					<h2>Latest Subscription Information</h2> <br/>
					{show_subscription()}
				</Row>	
			}
			{
				(InvoiceList.length>0) && 
					<Row>
						<h2>Invoices</h2>
						{show_invoice()}
					</Row>	
			}
			{
				CompanyMember && 
				<Row style={{marginTop:'20px'}}>
					<h2>Member List Setting</h2>
					{show_company_members()}
				</Row>		
			}
		</div>
	)
}

export default withRouter(PricingInfoPage);
