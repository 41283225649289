import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PdfImg01 from '../../../../assets/images/VideoSign/pdfimg03.jpeg';

function ESignature() {
  const { t } = useTranslation();

  return (
    <div style={{ padding: '150px 10% 80px' }}>
      <div className={'text-title'}>{t('eSignature')}</div>

      <div className={'text-content'}>
        <div>
          {t(
            'Electronic contract that provides the easiest but fastest and strongest legal protection.'
          )}
        </div>
        <div>
          {t(
            'The biggest problem with current electronic contract solutions is that they are complicated to use.'
          )}
        </div>
        <div>
          {t(
            'I tried several electronic contract programs, but all of them had no choice but to give up in the middle. The biggest problem was the incomprehensible UI. I tried all solutions, but I had a hard time understanding how the program worked.'
          )}
        </div>
        <br />
        <div>
          {t(
            'VidioSign has made contract creation very easy. Like the slogan “E-Sign for Dummy”, VidioSign made it easy for any idiot to sign an electronic contract.'
          )}
        </div>
        <div>
          {t(
            'In VidioSign, if the other party to the contract presses the “Approve” button once, it is automatically entered into the places where signatures are required, and a contract certificate is issued.'
          )}
        </div>
        <div>
          {t(
            'One of the advantages of VidioSign is the additional issued certificate. As soon as the contract is completed, users will receive the certificate along with the e-contract by email.'
          )}
        </div>
        <div>
          {t('署名が必要な場所に自動的に入力され、契約証明書が発行されます。')}
        </div>
        <div>{t('VidioSignの利点の1つは、追加発行される証明書です。')}</div>
        <div>
          {t(
            '契約が完了すると同時に、ユーザーは電子契約書とNFT証明書をメールで受け取ることができます。'
          )}
        </div>
      </div>
    </div>
  );
}

export default ESignature;
