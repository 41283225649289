import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Row, Button, Tabs, Input, Typography } from "antd";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import MyInfoPage from "./Sub/MyInfoPage";
import PricingInfoPage from "./Sub/PricingInfoPage";



function MyPage(props) {
    const { Title } = Typography;
    const { t } = useTranslation();
    const start_tab = props.match.params.start_tab;

    const items = [
        { label: t("My Info"), key: "myinfo", children: <MyInfoPage /> },
        { label: t("Pricing Information"), key: "priceinfo", children: <PricingInfoPage/> }
    ]

    function onTabClick(key) {
        props.history.push(`/home/mypage/${key}`);
    }

    return (
        <div className="dashboardContentWrap">
            <Tabs className="customTab" defaultActiveKey={start_tab} items={items} centered onTabClick={onTabClick}/>
        </div>
    );
}

export default withRouter(MyPage);
