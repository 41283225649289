import React, { useEffect, useState } from "react";
import { Row, Col, Button, Typography, Progress, Divider } from "antd";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSelector } from "react-redux";

// login한 상태에서 my_plan을 선택하면 보이는 화면
function MyPlan(props) {
    const { Text, Title } = Typography;
    const { t } = useTranslation();
	const user = useSelector((state) => state.user);

	const [LeftDays, setLeftDays] = useState(0);
	const [MaxDays, setMaxDays] = useState(30);
	const [Percent, setPercent] = useState(0);
	

	// useEffect(()=>{
	// 	async function get_current_plan(){
	// 		const res = await axios.post('/api/user/get_current_plan', {});
	// 		if (res.data.success){
	// 			return res.data.result;
	// 		}
	// 		return null;
	// 	}
	// 	const plan_info = get_current_plan();
	// })

	useEffect(()=>{
		const currDate = new Date();
		const validDate = new Date(user.userData.valid_date);
		const diffTime = Math.max(0, Math.abs(validDate - currDate));
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		setLeftDays(diffDays);

		switch (user.userData.plan) {
			case 'trial': setMaxDays(31);	break;
			case 'year_personal':	setMaxDays(365);	break;
			case 'year_standard':	setMaxDays(365);	break;
			case 'month_personal':	setMaxDays(31);	break;
			case 'month_standard':	setMaxDays(31);	break;
		}

		setPercent(LeftDays*100/MaxDays);

	})

	function onClickViewPlan() {
		props.history.push('/home/select_plan');
	}

	//////////////////////////////////////////////////////////////////
	return (<div>
		<Row justify='center'style={{marginTop:'100px'}}>
			<Col span={10}>
				Your Current Plans: {user.userData.plan} <br/>
				Days Left : {LeftDays} / {MaxDays} <br/>
				<Progress percent={Percent} showInfo={false}/>
			</Col>
		</Row>

		<Divider  style={{marginTop:'50px'}}/>

		<Row justify='center' style={{marginTop:'50px'}}>
			<Col span={10}>
				{user.userData.plan==='trial' &&
					`Your trial ends at ${user.userData.valid_date}. Loving VidioSign? Let's find the perfect plan for you.` 
				}
				<br/>
				<Button type='primary' onClick={onClickViewPlan} style={{marginTop:'30px'}}>VIEW PLANS</Button>
			</Col>
		</Row>
	</div>);
}

export default withRouter(MyPlan);
