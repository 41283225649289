
import { useState, useEffect, Fragment } from 'react';

import { Button, Result } from 'antd';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

const CheckoutForm = () => {

    const stripe = useStripe();
    const elements = useElements();
    const { lang } = useParams();
	const location = useLocation();

    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(async ({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
					console.log('paymentIntent', paymentIntent)
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });

    }, [stripe]);

    const handleSubmit = async (event) => {

        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

		
        setIsLoading(true);


		console.log('before confirmPayment', location, window.location.href);
        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                //return_url: `https://vidio.starnetnft.com/${lang}/plans?isModalOpen=true`,
				return_url: `${window.location.href}?isModalOpen=true`,
            },
        });
		console.log('after confirmPayment')

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };


    return (

        <Fragment>
            {
				(message === '') ? <form onSubmit={handleSubmit}>
										<PaymentElement />
										<button disabled={isLoading || !stripe || !elements}>Submit</button>
									</form> :
									<Result
										status="success"
										title="Successfully Purchased!"
										subTitle={message}
										extra={[
											// <Button type="primary" key="console">Go Console</Button>
											<Link to="/" key="console">Home</Link>
										]}
									/>
            }
        </Fragment>
    )
};

export default CheckoutForm;
